import { BaseAPI } from "../client/base";
import { AxiosResponse } from 'axios';

export interface UserOutDTO {
  access_token: string;
}


export interface UserLoginDTO {
  grant_type: string;
  username: string;
  password: string;
}

export class LoginControllerApi extends BaseAPI {
  public loginUser(userLoginDTO: any, config?: any): Promise<AxiosResponse<UserOutDTO>> {
    return this.axios.post('/oauth/token', userLoginDTO, config);
  }
}
