import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, createStore, Middleware } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducers, { history } from './redux';
import { Provider } from 'react-redux';
import AppRouter from './routes/AppRouter';
import { routerMiddleware } from 'connected-react-router';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { PRIMARY_MAIN_COLOR } from './config/style';

let middlewares: Middleware[] = [thunk, routerMiddleware(history)];
const createStoreWithMiddleware = applyMiddleware(...middlewares)(createStore);
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(logger);
}

const theme = createTheme({
  palette: {
    primary: {
      main: PRIMARY_MAIN_COLOR
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Provider store={createStoreWithMiddleware(rootReducers)}>
        <AppRouter />
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
