enum InstallationActionTypesEnum {
  SET_INSTALLATIONS = 'SET_INSTALLATIONS',
  SET_ONBOARDING_DATA_TEMPLATE = "SET_ONBOARDING_DATA_TEMPLATE",
  SET_ONBOARDING_DATA_INSTALLATION_DATA = "SET_ONBOARDING_DATA_INSTALLATION_DATA",
  SET_ONBOARDING_DATA_LOCATION = "SET_ONBOARDING_DATA_LOCATION",
  SET_ONBOARDING_DATA_CONTINENT = "SET_ONBOARDING_DATA_CONTINENT",
  RESET_ONBOARDING_DATA = "RESET_ONBOARDING_DATA",
  SET_SELECTED_INSTALLATION = "SET_SELECTED_INSTALLATION",
  SAVE_INSTALLATION_BY_ID = "SAVE_INSTALLATION_BY_ID"
}

export default InstallationActionTypesEnum;
