import i18next from "i18next";
import en from "./_translations/en";
import it from "./_translations/it";


i18next
  .init({
    interpolation: {
      escapeValue: false,
    },
    fallbackLng: "en",
    resources: {
      en: {
        translation: en
      },
      it: {
        translation: it
      },
    },
  });
export default i18next;
