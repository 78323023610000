import itFlag from '../../../assets/Images/flags/It.jpeg';
import enFlag from '../../../assets/Images/flags/UK.png';

export enum LANGUAGES {
  ENGLISH = 'ENGLISH',
  ITALIAN = 'ITALIAN',
};

export const LANGUAGE_DETAILS = [
  { key: LANGUAGES.ENGLISH, flag: enFlag, lang: 'en', moment: 'en', backend: LANGUAGES.ENGLISH },
  { key: LANGUAGES.ITALIAN, flag: itFlag, lang: 'it', moment: 'it', backend: LANGUAGES.ITALIAN },
];
