import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../redux';
import IDispatchProps from '../../redux/IDispatchProps';
import { InstallationActions } from '../../redux/installation/installation.actions';
import { InstallationData } from '../../redux/installation/model/Onboarding';
import ReduxLanguage from '../Language/ReduxLanguage';
import _ from 'lodash';
import { visualStoreVersionApi } from '../../repository/vitruvian/vitruvianApi';
import { VisualStoreVersionOutDTO } from '../../repository/vitruvian/client';

interface InstallationFormProps extends IDispatchProps {
  installationData?: InstallationData;
}

interface InstallationFormState {
  versions: VisualStoreVersionOutDTO[];
}

class InstallationForm extends React.Component<InstallationFormProps, InstallationFormState> {

  constructor(props: InstallationFormProps) {
    super(props);

    this.state = {
      versions: [],
    };
  }

  async componentDidMount() {
    try {
      const versions = await visualStoreVersionApi.getVisualStoreVersionsUsingGET();
      this.setState({
        versions: versions.data
      })
    } catch (e) {

    }
  }

  onValueChange(key: string, value: any) {
    const { dispatch } = this.props;

    dispatch(InstallationActions.setOnboardingInstallationData({ key, value }));
  }

  onSelectValueChange(key: string, value: any) {
    const { dispatch } = this.props;

    if (value) {
      dispatch(InstallationActions.setOnboardingInstallationData({ key, value: JSON.parse(value) }));
    }

  }


  render() {
    const { installationData } = this.props;
    const { versions } = this.state;

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: '100%'
        }}
      >
        <React.Fragment>
          <TextField
            autoComplete='off'
            size="small"
            fullWidth
            label={<ReduxLanguage t="installations.name" />}
            id="username"
            onChange={e => this.onValueChange("name", e.target.value)}
            sx={{
              backgroundColor: "white",
            }}
            variant="outlined"
            value={installationData?.name || ""}
          />
          <TextField
            autoComplete='off'
            size="small"
            fullWidth
            label={<ReduxLanguage t="installations.description" />}
            id="password"
            onChange={e => this.onValueChange("description", e.target.value)}
            sx={{ backgroundColor: "white" }}
            style={{ marginTop: 20 }}
            value={installationData?.description || ""}
          />
          <FormControl fullWidth style={{ marginTop: 20 }}>
            <InputLabel id="demo-simple-select-label"><ReduxLanguage t="installations.version" /></InputLabel>
            <Select
              id="demo-simple-select"
              value={JSON.stringify(installationData?.version) || ""}
              onChange={e => this.onSelectValueChange("version", e.target.value)}
              style={{ width: '50%' }}
              sx={{ backgroundColor: "white" }}
              labelId="demo-simple-select-label"
              label={<ReduxLanguage t="installations.version" />}
            >
              {_.map(versions, e => (
                <MenuItem key={e.id} value={JSON.stringify(e)}>{e.version}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControlLabel
            style={{ marginTop: 20 }}
            checked={installationData?.isDemo || false}
            control={
              <Checkbox
                onChange={e => this.onValueChange("isDemo", e.target.checked)}
              />
            }
            label={<ReduxLanguage t="installations.enabledDemoData" />}
          />
        </React.Fragment>
      </Box>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    installationData: state.installation.onboardingData.installationData
  }
}
export default connect(mapStateToProps)(InstallationForm);
