/* tslint:disable */
/* eslint-disable */
/**
 * Vitruvian APIs
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface InstanceDetailsOutDTO
 */
export interface InstanceDetailsOutDTO {
    /**
     * 
     * @type {number}
     * @memberof InstanceDetailsOutDTO
     */
    actualCost?: number;
    /**
     * 
     * @type {number}
     * @memberof InstanceDetailsOutDTO
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceDetailsOutDTO
     */
    creationLog?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceDetailsOutDTO
     */
    customer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstanceDetailsOutDTO
     */
    demoDataEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InstanceDetailsOutDTO
     */
    description?: string;
    /**
     * 
     * @type {Array<InstanceExposedServiceDTO>}
     * @memberof InstanceDetailsOutDTO
     */
    exposedServices?: Array<InstanceExposedServiceDTO>;
    /**
     * 
     * @type {number}
     * @memberof InstanceDetailsOutDTO
     */
    id?: number;
    /**
     * 
     * @type {Array<InstanceServiceStatusOutDTO>}
     * @memberof InstanceDetailsOutDTO
     */
    instanceServicesStatus?: Array<InstanceServiceStatusOutDTO>;
    /**
     * 
     * @type {LocationOutDTO}
     * @memberof InstanceDetailsOutDTO
     */
    locationOutDTO?: LocationOutDTO;
    /**
     * 
     * @type {string}
     * @memberof InstanceDetailsOutDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceDetailsOutDTO
     */
    status?: string;
    /**
     * 
     * @type {TemplateOutDTO}
     * @memberof InstanceDetailsOutDTO
     */
    templateOutDTO?: TemplateOutDTO;
    /**
     * 
     * @type {UserOutDTO}
     * @memberof InstanceDetailsOutDTO
     */
    userOutDTO?: UserOutDTO;
    /**
     * 
     * @type {VisualStoreVersionOutDTO}
     * @memberof InstanceDetailsOutDTO
     */
    visualStoreVersionOutDTO?: VisualStoreVersionOutDTO;
}
/**
 * 
 * @export
 * @interface InstanceExposedServiceDTO
 */
export interface InstanceExposedServiceDTO {
    /**
     * 
     * @type {string}
     * @memberof InstanceExposedServiceDTO
     */
    hostname?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceExposedServiceDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface InstanceInDTO
 */
export interface InstanceInDTO {
    /**
     * 
     * @type {string}
     * @memberof InstanceInDTO
     */
    customer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstanceInDTO
     */
    demoDataEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InstanceInDTO
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof InstanceInDTO
     */
    locationId?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceInDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof InstanceInDTO
     */
    templateId?: number;
    /**
     * 
     * @type {number}
     * @memberof InstanceInDTO
     */
    visualStoreVersionId?: number;
}
/**
 * 
 * @export
 * @interface InstanceOutDTO
 */
export interface InstanceOutDTO {
    /**
     * 
     * @type {number}
     * @memberof InstanceOutDTO
     */
    createdAt?: number;
    /**
     * 
     * @type {string}
     * @memberof InstanceOutDTO
     */
    customer?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InstanceOutDTO
     */
    demoDataEnabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InstanceOutDTO
     */
    description?: string;
    /**
     * 
     * @type {Array<InstanceExposedServiceDTO>}
     * @memberof InstanceOutDTO
     */
    exposedServices?: Array<InstanceExposedServiceDTO>;
    /**
     * 
     * @type {number}
     * @memberof InstanceOutDTO
     */
    id?: number;
    /**
     * 
     * @type {LocationOutDTO}
     * @memberof InstanceOutDTO
     */
    locationOutDTO?: LocationOutDTO;
    /**
     * 
     * @type {string}
     * @memberof InstanceOutDTO
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceOutDTO
     */
    status?: string;
    /**
     * 
     * @type {TemplateOutDTO}
     * @memberof InstanceOutDTO
     */
    templateOutDTO?: TemplateOutDTO;
    /**
     * 
     * @type {UserOutDTO}
     * @memberof InstanceOutDTO
     */
    userOutDTO?: UserOutDTO;
    /**
     * 
     * @type {VisualStoreVersionOutDTO}
     * @memberof InstanceOutDTO
     */
    visualStoreVersionOutDTO?: VisualStoreVersionOutDTO;
}
/**
 * 
 * @export
 * @interface InstanceServiceStatusOutDTO
 */
export interface InstanceServiceStatusOutDTO {
    /**
     * 
     * @type {string}
     * @memberof InstanceServiceStatusOutDTO
     */
    serviceName?: string;
    /**
     * 
     * @type {string}
     * @memberof InstanceServiceStatusOutDTO
     */
    status?: InstanceServiceStatusOutDTOStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InstanceServiceStatusOutDTOStatusEnum {
    Ok = 'OK',
    Ko = 'KO'
}

/**
 * 
 * @export
 * @interface InstanceUpdateInDTO
 */
export interface InstanceUpdateInDTO {
    /**
     * 
     * @type {string}
     * @memberof InstanceUpdateInDTO
     */
    instanceStatus?: InstanceUpdateInDTOInstanceStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum InstanceUpdateInDTOInstanceStatusEnum {
    Pending = 'PENDING',
    Active = 'ACTIVE',
    Error = 'ERROR'
}

/**
 * 
 * @export
 * @interface LocationInDTO
 */
export interface LocationInDTO {
    /**
     * 
     * @type {string}
     * @memberof LocationInDTO
     */
    continent?: LocationInDTOContinentEnum;
    /**
     * 
     * @type {string}
     * @memberof LocationInDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationInDTO
     */
    providerId?: number;
}

/**
    * @export
    * @enum {string}
    */
export enum LocationInDTOContinentEnum {
    NorthAmerica = 'NORTH_AMERICA',
    SouthAmerica = 'SOUTH_AMERICA',
    MiddleEast = 'MIDDLE_EAST',
    AsiaPacific = 'ASIA_PACIFIC',
    Africa = 'AFRICA',
    Europe = 'EUROPE',
    Australia = 'AUSTRALIA'
}

/**
 * 
 * @export
 * @interface LocationOutDTO
 */
export interface LocationOutDTO {
    /**
     * 
     * @type {string}
     * @memberof LocationOutDTO
     */
    continent?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationOutDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LocationOutDTO
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof LocationOutDTO
     */
    providerId?: number;
}
/**
 * 
 * @export
 * @interface ModelFile
 */
export interface ModelFile {
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    absolute?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    absoluteFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    absolutePath?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    canonicalFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    canonicalPath?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    directory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    file?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    freeSpace?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ModelFile
     */
    hidden?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    parent?: string;
    /**
     * 
     * @type {any}
     * @memberof ModelFile
     */
    parentFile?: any;
    /**
     * 
     * @type {string}
     * @memberof ModelFile
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    totalSpace?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelFile
     */
    usableSpace?: number;
}
/**
 * 
 * @export
 * @interface PageOfInstanceOutDTO
 */
export interface PageOfInstanceOutDTO {
    /**
     * 
     * @type {Array<InstanceOutDTO>}
     * @memberof PageOfInstanceOutDTO
     */
    content?: Array<InstanceOutDTO>;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfInstanceOutDTO
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfInstanceOutDTO
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOfInstanceOutDTO
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOfInstanceOutDTO
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfInstanceOutDTO
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOfInstanceOutDTO
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageOfInstanceOutDTO
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOfInstanceOutDTO
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOfInstanceOutDTO
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOfInstanceOutDTO
     */
    totalPages?: number;
}
/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    offset?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    pageSize?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    paged?: boolean;
    /**
     * 
     * @type {Sort}
     * @memberof Pageable
     */
    sort?: Sort;
    /**
     * 
     * @type {boolean}
     * @memberof Pageable
     */
    unpaged?: boolean;
}
/**
 * 
 * @export
 * @interface PresignedUrlDTO
 */
export interface PresignedUrlDTO {
    /**
     * 
     * @type {string}
     * @memberof PresignedUrlDTO
     */
    presignedUrl?: string;
}
/**
 * 
 * @export
 * @interface ProviderInDTO
 */
export interface ProviderInDTO {
    /**
     * 
     * @type {string}
     * @memberof ProviderInDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ProviderOutDTO
 */
export interface ProviderOutDTO {
    /**
     * 
     * @type {number}
     * @memberof ProviderOutDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ProviderOutDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface ProviderUpdateInDTO
 */
export interface ProviderUpdateInDTO {
    /**
     * 
     * @type {string}
     * @memberof ProviderUpdateInDTO
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface Resource
 */
export interface Resource {
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    description?: string;
    /**
     * 
     * @type {any}
     * @memberof Resource
     */
    file?: any;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    filename?: string;
    /**
     * 
     * @type {object}
     * @memberof Resource
     */
    inputStream?: object;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    open?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Resource
     */
    readable?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Resource
     */
    uri?: string;
    /**
     * 
     * @type {URL}
     * @memberof Resource
     */
    url?: URL;
}
/**
 * 
 * @export
 * @interface S3ObjectDTO
 */
export interface S3ObjectDTO {
    /**
     * 
     * @type {string}
     * @memberof S3ObjectDTO
     */
    key?: string;
    /**
     * 
     * @type {number}
     * @memberof S3ObjectDTO
     */
    lastModified?: number;
    /**
     * 
     * @type {number}
     * @memberof S3ObjectDTO
     */
    size?: number;
}
/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
}
/**
 * 
 * @export
 * @interface TemplateInDTO
 */
export interface TemplateInDTO {
    /**
     * 
     * @type {number}
     * @memberof TemplateInDTO
     */
    costPerHour?: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateInDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateInDTO
     */
    environment?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof TemplateInDTO
     */
    exposedServices?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof TemplateInDTO
     */
    providerId?: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateInDTO
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateInDTO
     */
    sku?: string;
}
/**
 * 
 * @export
 * @interface TemplateOutDTO
 */
export interface TemplateOutDTO {
    /**
     * 
     * @type {number}
     * @memberof TemplateOutDTO
     */
    costPerHour?: number;
    /**
     * 
     * @type {string}
     * @memberof TemplateOutDTO
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateOutDTO
     */
    environment?: string;
    /**
     * 
     * @type {number}
     * @memberof TemplateOutDTO
     */
    id?: number;
    /**
     * 
     * @type {ProviderOutDTO}
     * @memberof TemplateOutDTO
     */
    providerOutDTO?: ProviderOutDTO;
    /**
     * 
     * @type {string}
     * @memberof TemplateOutDTO
     */
    size?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateOutDTO
     */
    sku?: string;
}
/**
 * 
 * @export
 * @interface URI
 */
export interface URI {
    /**
     * 
     * @type {boolean}
     * @memberof URI
     */
    absolute?: boolean;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    authority?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    fragment?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    host?: string;
    /**
     * 
     * @type {boolean}
     * @memberof URI
     */
    opaque?: boolean;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof URI
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawAuthority?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawFragment?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawPath?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawQuery?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawSchemeSpecificPart?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    rawUserInfo?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    scheme?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    schemeSpecificPart?: string;
    /**
     * 
     * @type {string}
     * @memberof URI
     */
    userInfo?: string;
}
/**
 * 
 * @export
 * @interface URL
 */
export interface URL {
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    authority?: string;
    /**
     * 
     * @type {object}
     * @memberof URL
     */
    content?: object;
    /**
     * 
     * @type {number}
     * @memberof URL
     */
    defaultPort?: number;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    file?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    host?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    path?: string;
    /**
     * 
     * @type {number}
     * @memberof URL
     */
    port?: number;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    protocol?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    query?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    ref?: string;
    /**
     * 
     * @type {string}
     * @memberof URL
     */
    userInfo?: string;
}
/**
 * 
 * @export
 * @interface UserOutDTO
 */
export interface UserOutDTO {
    /**
     * 
     * @type {boolean}
     * @memberof UserOutDTO
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserOutDTO
     */
    email?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserOutDTO
     */
    enabled?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UserOutDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof UserOutDTO
     */
    username?: string;
}
/**
 * 
 * @export
 * @interface VisualStoreVersionInDTO
 */
export interface VisualStoreVersionInDTO {
    /**
     * 
     * @type {string}
     * @memberof VisualStoreVersionInDTO
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface VisualStoreVersionOutDTO
 */
export interface VisualStoreVersionOutDTO {
    /**
     * 
     * @type {number}
     * @memberof VisualStoreVersionOutDTO
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof VisualStoreVersionOutDTO
     */
    version?: string;
}
/**
 * 
 * @export
 * @interface VisualStoreVersionUpdateInDTO
 */
export interface VisualStoreVersionUpdateInDTO {
    /**
     * 
     * @type {string}
     * @memberof VisualStoreVersionUpdateInDTO
     */
    version?: string;
}

/**
 * InstanceControllerApi - axios parameter creator
 * @export
 */
export const InstanceControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createInstance
         * @param {InstanceInDTO} instanceInDTO instanceInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstanceUsingPOST: async (instanceInDTO: InstanceInDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceInDTO' is not null or undefined
            assertParamExists('createInstanceUsingPOST', 'instanceInDTO', instanceInDTO)
            const localVarPath = `/api/v1/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instanceInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInstanceDetails
         * @param {number} instanceId instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceDetailsUsingGET: async (instanceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getInstanceDetailsUsingGET', 'instanceId', instanceId)
            const localVarPath = `/api/v1/instances/{instanceId}/details`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInstanceSecrets
         * @param {number} instanceId instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceSecretsUsingGET: async (instanceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getInstanceSecretsUsingGET', 'instanceId', instanceId)
            const localVarPath = `/api/v1/instances/{instanceId}/secrets`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInstance
         * @param {number} instanceId instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceUsingGET: async (instanceId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('getInstanceUsingGET', 'instanceId', instanceId)
            const localVarPath = `/api/v1/instances/{instanceId}`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInstances
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {'ASC' | 'DESC'} [sortDir] sortDir
         * @param {'id'} [sortProperty] sortProperty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesUsingGET: async (page?: number, pageSize?: number, sortDir?: 'ASC' | 'DESC', sortProperty?: 'id', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/instances`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (sortDir !== undefined) {
                localVarQueryParameter['sortDir'] = sortDir;
            }

            if (sortProperty !== undefined) {
                localVarQueryParameter['sortProperty'] = sortProperty;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateInstance
         * @param {number} instanceId instanceId
         * @param {InstanceUpdateInDTO} instanceUpdateInDTO instanceUpdateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstanceUsingPUT: async (instanceId: number, instanceUpdateInDTO: InstanceUpdateInDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'instanceId' is not null or undefined
            assertParamExists('updateInstanceUsingPUT', 'instanceId', instanceId)
            // verify required parameter 'instanceUpdateInDTO' is not null or undefined
            assertParamExists('updateInstanceUsingPUT', 'instanceUpdateInDTO', instanceUpdateInDTO)
            const localVarPath = `/api/v1/instances/{instanceId}`
                .replace(`{${"instanceId"}}`, encodeURIComponent(String(instanceId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(instanceUpdateInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * InstanceControllerApi - functional programming interface
 * @export
 */
export const InstanceControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = InstanceControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createInstance
         * @param {InstanceInDTO} instanceInDTO instanceInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createInstanceUsingPOST(instanceInDTO: InstanceInDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createInstanceUsingPOST(instanceInDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getInstanceDetails
         * @param {number} instanceId instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstanceDetailsUsingGET(instanceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceDetailsOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstanceDetailsUsingGET(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getInstanceSecrets
         * @param {number} instanceId instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstanceSecretsUsingGET(instanceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Resource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstanceSecretsUsingGET(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getInstance
         * @param {number} instanceId instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstanceUsingGET(instanceId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstanceUsingGET(instanceId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getInstances
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {'ASC' | 'DESC'} [sortDir] sortDir
         * @param {'id'} [sortProperty] sortProperty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getInstancesUsingGET(page?: number, pageSize?: number, sortDir?: 'ASC' | 'DESC', sortProperty?: 'id', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PageOfInstanceOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getInstancesUsingGET(page, pageSize, sortDir, sortProperty, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateInstance
         * @param {number} instanceId instanceId
         * @param {InstanceUpdateInDTO} instanceUpdateInDTO instanceUpdateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateInstanceUsingPUT(instanceId: number, instanceUpdateInDTO: InstanceUpdateInDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstanceOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateInstanceUsingPUT(instanceId, instanceUpdateInDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * InstanceControllerApi - factory interface
 * @export
 */
export const InstanceControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = InstanceControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary createInstance
         * @param {InstanceInDTO} instanceInDTO instanceInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInstanceUsingPOST(instanceInDTO: InstanceInDTO, options?: any): AxiosPromise<InstanceOutDTO> {
            return localVarFp.createInstanceUsingPOST(instanceInDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getInstanceDetails
         * @param {number} instanceId instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceDetailsUsingGET(instanceId: number, options?: any): AxiosPromise<InstanceDetailsOutDTO> {
            return localVarFp.getInstanceDetailsUsingGET(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getInstanceSecrets
         * @param {number} instanceId instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceSecretsUsingGET(instanceId: number, options?: any): AxiosPromise<Resource> {
            return localVarFp.getInstanceSecretsUsingGET(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getInstance
         * @param {number} instanceId instanceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstanceUsingGET(instanceId: number, options?: any): AxiosPromise<InstanceOutDTO> {
            return localVarFp.getInstanceUsingGET(instanceId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getInstances
         * @param {number} [page] page
         * @param {number} [pageSize] pageSize
         * @param {'ASC' | 'DESC'} [sortDir] sortDir
         * @param {'id'} [sortProperty] sortProperty
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInstancesUsingGET(page?: number, pageSize?: number, sortDir?: 'ASC' | 'DESC', sortProperty?: 'id', options?: any): AxiosPromise<PageOfInstanceOutDTO> {
            return localVarFp.getInstancesUsingGET(page, pageSize, sortDir, sortProperty, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateInstance
         * @param {number} instanceId instanceId
         * @param {InstanceUpdateInDTO} instanceUpdateInDTO instanceUpdateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInstanceUsingPUT(instanceId: number, instanceUpdateInDTO: InstanceUpdateInDTO, options?: any): AxiosPromise<InstanceOutDTO> {
            return localVarFp.updateInstanceUsingPUT(instanceId, instanceUpdateInDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * InstanceControllerApi - interface
 * @export
 * @interface InstanceControllerApi
 */
export interface InstanceControllerApiInterface {
    /**
     * 
     * @summary createInstance
     * @param {InstanceInDTO} instanceInDTO instanceInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApiInterface
     */
    createInstanceUsingPOST(instanceInDTO: InstanceInDTO, options?: any): AxiosPromise<InstanceOutDTO>;

    /**
     * 
     * @summary getInstanceDetails
     * @param {number} instanceId instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApiInterface
     */
    getInstanceDetailsUsingGET(instanceId: number, options?: any): AxiosPromise<InstanceDetailsOutDTO>;

    /**
     * 
     * @summary getInstanceSecrets
     * @param {number} instanceId instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApiInterface
     */
    getInstanceSecretsUsingGET(instanceId: number, options?: any): AxiosPromise<Resource>;

    /**
     * 
     * @summary getInstance
     * @param {number} instanceId instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApiInterface
     */
    getInstanceUsingGET(instanceId: number, options?: any): AxiosPromise<InstanceOutDTO>;

    /**
     * 
     * @summary getInstances
     * @param {number} [page] page
     * @param {number} [pageSize] pageSize
     * @param {'ASC' | 'DESC'} [sortDir] sortDir
     * @param {'id'} [sortProperty] sortProperty
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApiInterface
     */
    getInstancesUsingGET(page?: number, pageSize?: number, sortDir?: 'ASC' | 'DESC', sortProperty?: 'id', options?: any): AxiosPromise<PageOfInstanceOutDTO>;

    /**
     * 
     * @summary updateInstance
     * @param {number} instanceId instanceId
     * @param {InstanceUpdateInDTO} instanceUpdateInDTO instanceUpdateInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApiInterface
     */
    updateInstanceUsingPUT(instanceId: number, instanceUpdateInDTO: InstanceUpdateInDTO, options?: any): AxiosPromise<InstanceOutDTO>;

}

/**
 * InstanceControllerApi - object-oriented interface
 * @export
 * @class InstanceControllerApi
 * @extends {BaseAPI}
 */
export class InstanceControllerApi extends BaseAPI implements InstanceControllerApiInterface {
    /**
     * 
     * @summary createInstance
     * @param {InstanceInDTO} instanceInDTO instanceInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApi
     */
    public createInstanceUsingPOST(instanceInDTO: InstanceInDTO, options?: any) {
        return InstanceControllerApiFp(this.configuration).createInstanceUsingPOST(instanceInDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getInstanceDetails
     * @param {number} instanceId instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApi
     */
    public getInstanceDetailsUsingGET(instanceId: number, options?: any) {
        return InstanceControllerApiFp(this.configuration).getInstanceDetailsUsingGET(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getInstanceSecrets
     * @param {number} instanceId instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApi
     */
    public getInstanceSecretsUsingGET(instanceId: number, options?: any) {
        return InstanceControllerApiFp(this.configuration).getInstanceSecretsUsingGET(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getInstance
     * @param {number} instanceId instanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApi
     */
    public getInstanceUsingGET(instanceId: number, options?: any) {
        return InstanceControllerApiFp(this.configuration).getInstanceUsingGET(instanceId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getInstances
     * @param {number} [page] page
     * @param {number} [pageSize] pageSize
     * @param {'ASC' | 'DESC'} [sortDir] sortDir
     * @param {'id'} [sortProperty] sortProperty
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApi
     */
    public getInstancesUsingGET(page?: number, pageSize?: number, sortDir?: 'ASC' | 'DESC', sortProperty?: 'id', options?: any) {
        return InstanceControllerApiFp(this.configuration).getInstancesUsingGET(page, pageSize, sortDir, sortProperty, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateInstance
     * @param {number} instanceId instanceId
     * @param {InstanceUpdateInDTO} instanceUpdateInDTO instanceUpdateInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof InstanceControllerApi
     */
    public updateInstanceUsingPUT(instanceId: number, instanceUpdateInDTO: InstanceUpdateInDTO, options?: any) {
        return InstanceControllerApiFp(this.configuration).updateInstanceUsingPUT(instanceId, instanceUpdateInDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * LocationControllerApi - axios parameter creator
 * @export
 */
export const LocationControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createLocation
         * @param {LocationInDTO} locationInDTO locationInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationUsingPOST: async (locationInDTO: LocationInDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'locationInDTO' is not null or undefined
            assertParamExists('createLocationUsingPOST', 'locationInDTO', locationInDTO)
            const localVarPath = `/api/v1/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(locationInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLocations
         * @param {'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA'} continent continent
         * @param {number} providerId providerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsUsingGET: async (continent: 'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA', providerId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'continent' is not null or undefined
            assertParamExists('getLocationsUsingGET', 'continent', continent)
            // verify required parameter 'providerId' is not null or undefined
            assertParamExists('getLocationsUsingGET', 'providerId', providerId)
            const localVarPath = `/api/v1/locations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (continent !== undefined) {
                localVarQueryParameter['continent'] = continent;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocationControllerApi - functional programming interface
 * @export
 */
export const LocationControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocationControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createLocation
         * @param {LocationInDTO} locationInDTO locationInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createLocationUsingPOST(locationInDTO: LocationInDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LocationOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createLocationUsingPOST(locationInDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getLocations
         * @param {'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA'} continent continent
         * @param {number} providerId providerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocationsUsingGET(continent: 'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA', providerId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LocationOutDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocationsUsingGET(continent, providerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LocationControllerApi - factory interface
 * @export
 */
export const LocationControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocationControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary createLocation
         * @param {LocationInDTO} locationInDTO locationInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLocationUsingPOST(locationInDTO: LocationInDTO, options?: any): AxiosPromise<LocationOutDTO> {
            return localVarFp.createLocationUsingPOST(locationInDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getLocations
         * @param {'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA'} continent continent
         * @param {number} providerId providerId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsUsingGET(continent: 'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA', providerId: number, options?: any): AxiosPromise<Array<LocationOutDTO>> {
            return localVarFp.getLocationsUsingGET(continent, providerId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocationControllerApi - interface
 * @export
 * @interface LocationControllerApi
 */
export interface LocationControllerApiInterface {
    /**
     * 
     * @summary createLocation
     * @param {LocationInDTO} locationInDTO locationInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApiInterface
     */
    createLocationUsingPOST(locationInDTO: LocationInDTO, options?: any): AxiosPromise<LocationOutDTO>;

    /**
     * 
     * @summary getLocations
     * @param {'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA'} continent continent
     * @param {number} providerId providerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApiInterface
     */
    getLocationsUsingGET(continent: 'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA', providerId: number, options?: any): AxiosPromise<Array<LocationOutDTO>>;

}

/**
 * LocationControllerApi - object-oriented interface
 * @export
 * @class LocationControllerApi
 * @extends {BaseAPI}
 */
export class LocationControllerApi extends BaseAPI implements LocationControllerApiInterface {
    /**
     * 
     * @summary createLocation
     * @param {LocationInDTO} locationInDTO locationInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public createLocationUsingPOST(locationInDTO: LocationInDTO, options?: any) {
        return LocationControllerApiFp(this.configuration).createLocationUsingPOST(locationInDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getLocations
     * @param {'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA'} continent continent
     * @param {number} providerId providerId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocationControllerApi
     */
    public getLocationsUsingGET(continent: 'NORTH_AMERICA' | 'SOUTH_AMERICA' | 'MIDDLE_EAST' | 'ASIA_PACIFIC' | 'AFRICA' | 'EUROPE' | 'AUSTRALIA', providerId: number, options?: any) {
        return LocationControllerApiFp(this.configuration).getLocationsUsingGET(continent, providerId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ProviderControllerApi - axios parameter creator
 * @export
 */
export const ProviderControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createProvider
         * @param {ProviderInDTO} providerInDTO providerInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderUsingPOST: async (providerInDTO: ProviderInDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'providerInDTO' is not null or undefined
            assertParamExists('createProviderUsingPOST', 'providerInDTO', providerInDTO)
            const localVarPath = `/api/v1/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProvider
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderUsingGET: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getProviderUsingGET', 'id', id)
            const localVarPath = `/api/v1/providers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProviders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/providers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateProvider
         * @param {number} id id
         * @param {ProviderUpdateInDTO} providerUpdateInDTO providerUpdateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderUsingPUT: async (id: number, providerUpdateInDTO: ProviderUpdateInDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateProviderUsingPUT', 'id', id)
            // verify required parameter 'providerUpdateInDTO' is not null or undefined
            assertParamExists('updateProviderUsingPUT', 'providerUpdateInDTO', providerUpdateInDTO)
            const localVarPath = `/api/v1/providers/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(providerUpdateInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProviderControllerApi - functional programming interface
 * @export
 */
export const ProviderControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProviderControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createProvider
         * @param {ProviderInDTO} providerInDTO providerInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createProviderUsingPOST(providerInDTO: ProviderInDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createProviderUsingPOST(providerInDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getProvider
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProviderUsingGET(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProviderUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getProviders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProvidersUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProviderOutDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProvidersUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateProvider
         * @param {number} id id
         * @param {ProviderUpdateInDTO} providerUpdateInDTO providerUpdateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateProviderUsingPUT(id: number, providerUpdateInDTO: ProviderUpdateInDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProviderOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateProviderUsingPUT(id, providerUpdateInDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ProviderControllerApi - factory interface
 * @export
 */
export const ProviderControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProviderControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary createProvider
         * @param {ProviderInDTO} providerInDTO providerInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProviderUsingPOST(providerInDTO: ProviderInDTO, options?: any): AxiosPromise<ProviderOutDTO> {
            return localVarFp.createProviderUsingPOST(providerInDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getProvider
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProviderUsingGET(id: number, options?: any): AxiosPromise<ProviderOutDTO> {
            return localVarFp.getProviderUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getProviders
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProvidersUsingGET(options?: any): AxiosPromise<Array<ProviderOutDTO>> {
            return localVarFp.getProvidersUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateProvider
         * @param {number} id id
         * @param {ProviderUpdateInDTO} providerUpdateInDTO providerUpdateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProviderUsingPUT(id: number, providerUpdateInDTO: ProviderUpdateInDTO, options?: any): AxiosPromise<ProviderOutDTO> {
            return localVarFp.updateProviderUsingPUT(id, providerUpdateInDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProviderControllerApi - interface
 * @export
 * @interface ProviderControllerApi
 */
export interface ProviderControllerApiInterface {
    /**
     * 
     * @summary createProvider
     * @param {ProviderInDTO} providerInDTO providerInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderControllerApiInterface
     */
    createProviderUsingPOST(providerInDTO: ProviderInDTO, options?: any): AxiosPromise<ProviderOutDTO>;

    /**
     * 
     * @summary getProvider
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderControllerApiInterface
     */
    getProviderUsingGET(id: number, options?: any): AxiosPromise<ProviderOutDTO>;

    /**
     * 
     * @summary getProviders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderControllerApiInterface
     */
    getProvidersUsingGET(options?: any): AxiosPromise<Array<ProviderOutDTO>>;

    /**
     * 
     * @summary updateProvider
     * @param {number} id id
     * @param {ProviderUpdateInDTO} providerUpdateInDTO providerUpdateInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderControllerApiInterface
     */
    updateProviderUsingPUT(id: number, providerUpdateInDTO: ProviderUpdateInDTO, options?: any): AxiosPromise<ProviderOutDTO>;

}

/**
 * ProviderControllerApi - object-oriented interface
 * @export
 * @class ProviderControllerApi
 * @extends {BaseAPI}
 */
export class ProviderControllerApi extends BaseAPI implements ProviderControllerApiInterface {
    /**
     * 
     * @summary createProvider
     * @param {ProviderInDTO} providerInDTO providerInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderControllerApi
     */
    public createProviderUsingPOST(providerInDTO: ProviderInDTO, options?: any) {
        return ProviderControllerApiFp(this.configuration).createProviderUsingPOST(providerInDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getProvider
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderControllerApi
     */
    public getProviderUsingGET(id: number, options?: any) {
        return ProviderControllerApiFp(this.configuration).getProviderUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getProviders
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderControllerApi
     */
    public getProvidersUsingGET(options?: any) {
        return ProviderControllerApiFp(this.configuration).getProvidersUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateProvider
     * @param {number} id id
     * @param {ProviderUpdateInDTO} providerUpdateInDTO providerUpdateInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProviderControllerApi
     */
    public updateProviderUsingPUT(id: number, providerUpdateInDTO: ProviderUpdateInDTO, options?: any) {
        return ProviderControllerApiFp(this.configuration).updateProviderUsingPUT(id, providerUpdateInDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * StorageControllerApi - axios parameter creator
 * @export
 */
export const StorageControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary copyObject
         * @param {string} bucket bucket
         * @param {string} dstObjectKey dstObjectKey
         * @param {string} srcObjectKey srcObjectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyObjectUsingPUT: async (bucket: string, dstObjectKey: string, srcObjectKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('copyObjectUsingPUT', 'bucket', bucket)
            // verify required parameter 'dstObjectKey' is not null or undefined
            assertParamExists('copyObjectUsingPUT', 'dstObjectKey', dstObjectKey)
            // verify required parameter 'srcObjectKey' is not null or undefined
            assertParamExists('copyObjectUsingPUT', 'srcObjectKey', srcObjectKey)
            const localVarPath = `/api/v1/storage/{bucket}/copy`
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (dstObjectKey !== undefined) {
                localVarQueryParameter['dstObjectKey'] = dstObjectKey;
            }

            if (srcObjectKey !== undefined) {
                localVarQueryParameter['srcObjectKey'] = srcObjectKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteObject
         * @param {string} bucket bucket
         * @param {string} objectKey objectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteObjectUsingDELETE: async (bucket: string, objectKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('deleteObjectUsingDELETE', 'bucket', bucket)
            // verify required parameter 'objectKey' is not null or undefined
            assertParamExists('deleteObjectUsingDELETE', 'objectKey', objectKey)
            const localVarPath = `/api/v1/storage/{bucket}`
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (objectKey !== undefined) {
                localVarQueryParameter['objectKey'] = objectKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getBucketsList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBucketsListUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/storage/buckets`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDownloadSignedUrl
         * @param {string} bucket bucket
         * @param {string} objectKey objectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadSignedUrlUsingGET: async (bucket: string, objectKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('getDownloadSignedUrlUsingGET', 'bucket', bucket)
            // verify required parameter 'objectKey' is not null or undefined
            assertParamExists('getDownloadSignedUrlUsingGET', 'objectKey', objectKey)
            const localVarPath = `/api/v1/storage/{bucket}/download`
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (objectKey !== undefined) {
                localVarQueryParameter['objectKey'] = objectKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getObjectsList
         * @param {string} bucket bucket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectsListUsingGET: async (bucket: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('getObjectsListUsingGET', 'bucket', bucket)
            const localVarPath = `/api/v1/storage/{bucket}/objects`
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUploadSignedUrl
         * @param {string} bucket bucket
         * @param {string} objectKey objectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrlUsingGET: async (bucket: string, objectKey: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'bucket' is not null or undefined
            assertParamExists('getUploadSignedUrlUsingGET', 'bucket', bucket)
            // verify required parameter 'objectKey' is not null or undefined
            assertParamExists('getUploadSignedUrlUsingGET', 'objectKey', objectKey)
            const localVarPath = `/api/v1/storage/{bucket}/upload`
                .replace(`{${"bucket"}}`, encodeURIComponent(String(bucket)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (objectKey !== undefined) {
                localVarQueryParameter['objectKey'] = objectKey;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StorageControllerApi - functional programming interface
 * @export
 */
export const StorageControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = StorageControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary copyObject
         * @param {string} bucket bucket
         * @param {string} dstObjectKey dstObjectKey
         * @param {string} srcObjectKey srcObjectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async copyObjectUsingPUT(bucket: string, dstObjectKey: string, srcObjectKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.copyObjectUsingPUT(bucket, dstObjectKey, srcObjectKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary deleteObject
         * @param {string} bucket bucket
         * @param {string} objectKey objectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteObjectUsingDELETE(bucket: string, objectKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteObjectUsingDELETE(bucket, objectKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getBucketsList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBucketsListUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBucketsListUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getDownloadSignedUrl
         * @param {string} bucket bucket
         * @param {string} objectKey objectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDownloadSignedUrlUsingGET(bucket: string, objectKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedUrlDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDownloadSignedUrlUsingGET(bucket, objectKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getObjectsList
         * @param {string} bucket bucket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getObjectsListUsingGET(bucket: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<S3ObjectDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getObjectsListUsingGET(bucket, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getUploadSignedUrl
         * @param {string} bucket bucket
         * @param {string} objectKey objectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUploadSignedUrlUsingGET(bucket: string, objectKey: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PresignedUrlDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUploadSignedUrlUsingGET(bucket, objectKey, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * StorageControllerApi - factory interface
 * @export
 */
export const StorageControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = StorageControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary copyObject
         * @param {string} bucket bucket
         * @param {string} dstObjectKey dstObjectKey
         * @param {string} srcObjectKey srcObjectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyObjectUsingPUT(bucket: string, dstObjectKey: string, srcObjectKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.copyObjectUsingPUT(bucket, dstObjectKey, srcObjectKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary deleteObject
         * @param {string} bucket bucket
         * @param {string} objectKey objectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteObjectUsingDELETE(bucket: string, objectKey: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteObjectUsingDELETE(bucket, objectKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getBucketsList
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBucketsListUsingGET(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.getBucketsListUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getDownloadSignedUrl
         * @param {string} bucket bucket
         * @param {string} objectKey objectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDownloadSignedUrlUsingGET(bucket: string, objectKey: string, options?: any): AxiosPromise<PresignedUrlDTO> {
            return localVarFp.getDownloadSignedUrlUsingGET(bucket, objectKey, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getObjectsList
         * @param {string} bucket bucket
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getObjectsListUsingGET(bucket: string, options?: any): AxiosPromise<Array<S3ObjectDTO>> {
            return localVarFp.getObjectsListUsingGET(bucket, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getUploadSignedUrl
         * @param {string} bucket bucket
         * @param {string} objectKey objectKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUploadSignedUrlUsingGET(bucket: string, objectKey: string, options?: any): AxiosPromise<PresignedUrlDTO> {
            return localVarFp.getUploadSignedUrlUsingGET(bucket, objectKey, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * StorageControllerApi - interface
 * @export
 * @interface StorageControllerApi
 */
export interface StorageControllerApiInterface {
    /**
     * 
     * @summary copyObject
     * @param {string} bucket bucket
     * @param {string} dstObjectKey dstObjectKey
     * @param {string} srcObjectKey srcObjectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApiInterface
     */
    copyObjectUsingPUT(bucket: string, dstObjectKey: string, srcObjectKey: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary deleteObject
     * @param {string} bucket bucket
     * @param {string} objectKey objectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApiInterface
     */
    deleteObjectUsingDELETE(bucket: string, objectKey: string, options?: any): AxiosPromise<void>;

    /**
     * 
     * @summary getBucketsList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApiInterface
     */
    getBucketsListUsingGET(options?: any): AxiosPromise<Array<string>>;

    /**
     * 
     * @summary getDownloadSignedUrl
     * @param {string} bucket bucket
     * @param {string} objectKey objectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApiInterface
     */
    getDownloadSignedUrlUsingGET(bucket: string, objectKey: string, options?: any): AxiosPromise<PresignedUrlDTO>;

    /**
     * 
     * @summary getObjectsList
     * @param {string} bucket bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApiInterface
     */
    getObjectsListUsingGET(bucket: string, options?: any): AxiosPromise<Array<S3ObjectDTO>>;

    /**
     * 
     * @summary getUploadSignedUrl
     * @param {string} bucket bucket
     * @param {string} objectKey objectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApiInterface
     */
    getUploadSignedUrlUsingGET(bucket: string, objectKey: string, options?: any): AxiosPromise<PresignedUrlDTO>;

}

/**
 * StorageControllerApi - object-oriented interface
 * @export
 * @class StorageControllerApi
 * @extends {BaseAPI}
 */
export class StorageControllerApi extends BaseAPI implements StorageControllerApiInterface {
    /**
     * 
     * @summary copyObject
     * @param {string} bucket bucket
     * @param {string} dstObjectKey dstObjectKey
     * @param {string} srcObjectKey srcObjectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApi
     */
    public copyObjectUsingPUT(bucket: string, dstObjectKey: string, srcObjectKey: string, options?: any) {
        return StorageControllerApiFp(this.configuration).copyObjectUsingPUT(bucket, dstObjectKey, srcObjectKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary deleteObject
     * @param {string} bucket bucket
     * @param {string} objectKey objectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApi
     */
    public deleteObjectUsingDELETE(bucket: string, objectKey: string, options?: any) {
        return StorageControllerApiFp(this.configuration).deleteObjectUsingDELETE(bucket, objectKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getBucketsList
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApi
     */
    public getBucketsListUsingGET(options?: any) {
        return StorageControllerApiFp(this.configuration).getBucketsListUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getDownloadSignedUrl
     * @param {string} bucket bucket
     * @param {string} objectKey objectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApi
     */
    public getDownloadSignedUrlUsingGET(bucket: string, objectKey: string, options?: any) {
        return StorageControllerApiFp(this.configuration).getDownloadSignedUrlUsingGET(bucket, objectKey, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getObjectsList
     * @param {string} bucket bucket
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApi
     */
    public getObjectsListUsingGET(bucket: string, options?: any) {
        return StorageControllerApiFp(this.configuration).getObjectsListUsingGET(bucket, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getUploadSignedUrl
     * @param {string} bucket bucket
     * @param {string} objectKey objectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageControllerApi
     */
    public getUploadSignedUrlUsingGET(bucket: string, objectKey: string, options?: any) {
        return StorageControllerApiFp(this.configuration).getUploadSignedUrlUsingGET(bucket, objectKey, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TemplateControllerApi - axios parameter creator
 * @export
 */
export const TemplateControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createTemplate
         * @param {TemplateInDTO} templateInDTO templateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateUsingPOST: async (templateInDTO: TemplateInDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'templateInDTO' is not null or undefined
            assertParamExists('createTemplateUsingPOST', 'templateInDTO', templateInDTO)
            const localVarPath = `/api/v1/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(templateInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTemplates
         * @param {string} [environment] environment
         * @param {number} [providerId] providerId
         * @param {string} [size] size
         * @param {string} [sku] sku
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplatesUsingGET: async (environment?: string, providerId?: number, size?: string, sku?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/templates`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)

            if (environment !== undefined) {
                localVarQueryParameter['environment'] = environment;
            }

            if (providerId !== undefined) {
                localVarQueryParameter['providerId'] = providerId;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sku !== undefined) {
                localVarQueryParameter['sku'] = sku;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TemplateControllerApi - functional programming interface
 * @export
 */
export const TemplateControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TemplateControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createTemplate
         * @param {TemplateInDTO} templateInDTO templateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createTemplateUsingPOST(templateInDTO: TemplateInDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TemplateOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createTemplateUsingPOST(templateInDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getTemplates
         * @param {string} [environment] environment
         * @param {number} [providerId] providerId
         * @param {string} [size] size
         * @param {string} [sku] sku
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTemplatesUsingGET(environment?: string, providerId?: number, size?: string, sku?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TemplateOutDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTemplatesUsingGET(environment, providerId, size, sku, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TemplateControllerApi - factory interface
 * @export
 */
export const TemplateControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TemplateControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary createTemplate
         * @param {TemplateInDTO} templateInDTO templateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTemplateUsingPOST(templateInDTO: TemplateInDTO, options?: any): AxiosPromise<TemplateOutDTO> {
            return localVarFp.createTemplateUsingPOST(templateInDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getTemplates
         * @param {string} [environment] environment
         * @param {number} [providerId] providerId
         * @param {string} [size] size
         * @param {string} [sku] sku
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplatesUsingGET(environment?: string, providerId?: number, size?: string, sku?: string, options?: any): AxiosPromise<Array<TemplateOutDTO>> {
            return localVarFp.getTemplatesUsingGET(environment, providerId, size, sku, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TemplateControllerApi - interface
 * @export
 * @interface TemplateControllerApi
 */
export interface TemplateControllerApiInterface {
    /**
     * 
     * @summary createTemplate
     * @param {TemplateInDTO} templateInDTO templateInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateControllerApiInterface
     */
    createTemplateUsingPOST(templateInDTO: TemplateInDTO, options?: any): AxiosPromise<TemplateOutDTO>;

    /**
     * 
     * @summary getTemplates
     * @param {string} [environment] environment
     * @param {number} [providerId] providerId
     * @param {string} [size] size
     * @param {string} [sku] sku
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateControllerApiInterface
     */
    getTemplatesUsingGET(environment?: string, providerId?: number, size?: string, sku?: string, options?: any): AxiosPromise<Array<TemplateOutDTO>>;

}

/**
 * TemplateControllerApi - object-oriented interface
 * @export
 * @class TemplateControllerApi
 * @extends {BaseAPI}
 */
export class TemplateControllerApi extends BaseAPI implements TemplateControllerApiInterface {
    /**
     * 
     * @summary createTemplate
     * @param {TemplateInDTO} templateInDTO templateInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateControllerApi
     */
    public createTemplateUsingPOST(templateInDTO: TemplateInDTO, options?: any) {
        return TemplateControllerApiFp(this.configuration).createTemplateUsingPOST(templateInDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getTemplates
     * @param {string} [environment] environment
     * @param {number} [providerId] providerId
     * @param {string} [size] size
     * @param {string} [sku] sku
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TemplateControllerApi
     */
    public getTemplatesUsingGET(environment?: string, providerId?: number, size?: string, sku?: string, options?: any) {
        return TemplateControllerApiFp(this.configuration).getTemplatesUsingGET(environment, providerId, size, sku, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserControllerApi - axios parameter creator
 * @export
 */
export const UserControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary getMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary getMe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeUsingGET(options?: any): AxiosPromise<UserOutDTO> {
            return localVarFp.getMeUsingGET(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserControllerApi - interface
 * @export
 * @interface UserControllerApi
 */
export interface UserControllerApiInterface {
    /**
     * 
     * @summary getMe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApiInterface
     */
    getMeUsingGET(options?: any): AxiosPromise<UserOutDTO>;

}

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI implements UserControllerApiInterface {
    /**
     * 
     * @summary getMe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getMeUsingGET(options?: any) {
        return UserControllerApiFp(this.configuration).getMeUsingGET(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VisualStoreVersionControllerApi - axios parameter creator
 * @export
 */
export const VisualStoreVersionControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createVisualStoreVersion
         * @param {VisualStoreVersionInDTO} visualStoreVersionInDTO visualStoreVersionInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVisualStoreVersionUsingPOST: async (visualStoreVersionInDTO: VisualStoreVersionInDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'visualStoreVersionInDTO' is not null or undefined
            assertParamExists('createVisualStoreVersionUsingPOST', 'visualStoreVersionInDTO', visualStoreVersionInDTO)
            const localVarPath = `/api/v1/visualstoreversions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualStoreVersionInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getVisualStoreVersion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisualStoreVersionUsingGET: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getVisualStoreVersionUsingGET', 'id', id)
            const localVarPath = `/api/v1/visualstoreversions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getVisualStoreVersions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisualStoreVersionsUsingGET: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/v1/visualstoreversions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateVisualStoreVersion
         * @param {number} id id
         * @param {VisualStoreVersionUpdateInDTO} visualStoreVersionUpdateInDTO visualStoreVersionUpdateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisualStoreVersionUsingPUT: async (id: number, visualStoreVersionUpdateInDTO: VisualStoreVersionUpdateInDTO, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateVisualStoreVersionUsingPUT', 'id', id)
            // verify required parameter 'visualStoreVersionUpdateInDTO' is not null or undefined
            assertParamExists('updateVisualStoreVersionUsingPUT', 'visualStoreVersionUpdateInDTO', visualStoreVersionUpdateInDTO)
            const localVarPath = `/api/v1/visualstoreversions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth2", [], configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(visualStoreVersionUpdateInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VisualStoreVersionControllerApi - functional programming interface
 * @export
 */
export const VisualStoreVersionControllerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VisualStoreVersionControllerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary createVisualStoreVersion
         * @param {VisualStoreVersionInDTO} visualStoreVersionInDTO visualStoreVersionInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createVisualStoreVersionUsingPOST(visualStoreVersionInDTO: VisualStoreVersionInDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualStoreVersionOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createVisualStoreVersionUsingPOST(visualStoreVersionInDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getVisualStoreVersion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisualStoreVersionUsingGET(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualStoreVersionOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisualStoreVersionUsingGET(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary getVisualStoreVersions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getVisualStoreVersionsUsingGET(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VisualStoreVersionOutDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getVisualStoreVersionsUsingGET(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary updateVisualStoreVersion
         * @param {number} id id
         * @param {VisualStoreVersionUpdateInDTO} visualStoreVersionUpdateInDTO visualStoreVersionUpdateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateVisualStoreVersionUsingPUT(id: number, visualStoreVersionUpdateInDTO: VisualStoreVersionUpdateInDTO, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VisualStoreVersionOutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateVisualStoreVersionUsingPUT(id, visualStoreVersionUpdateInDTO, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VisualStoreVersionControllerApi - factory interface
 * @export
 */
export const VisualStoreVersionControllerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VisualStoreVersionControllerApiFp(configuration)
    return {
        /**
         * 
         * @summary createVisualStoreVersion
         * @param {VisualStoreVersionInDTO} visualStoreVersionInDTO visualStoreVersionInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createVisualStoreVersionUsingPOST(visualStoreVersionInDTO: VisualStoreVersionInDTO, options?: any): AxiosPromise<VisualStoreVersionOutDTO> {
            return localVarFp.createVisualStoreVersionUsingPOST(visualStoreVersionInDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getVisualStoreVersion
         * @param {number} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisualStoreVersionUsingGET(id: number, options?: any): AxiosPromise<VisualStoreVersionOutDTO> {
            return localVarFp.getVisualStoreVersionUsingGET(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary getVisualStoreVersions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getVisualStoreVersionsUsingGET(options?: any): AxiosPromise<Array<VisualStoreVersionOutDTO>> {
            return localVarFp.getVisualStoreVersionsUsingGET(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary updateVisualStoreVersion
         * @param {number} id id
         * @param {VisualStoreVersionUpdateInDTO} visualStoreVersionUpdateInDTO visualStoreVersionUpdateInDTO
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateVisualStoreVersionUsingPUT(id: number, visualStoreVersionUpdateInDTO: VisualStoreVersionUpdateInDTO, options?: any): AxiosPromise<VisualStoreVersionOutDTO> {
            return localVarFp.updateVisualStoreVersionUsingPUT(id, visualStoreVersionUpdateInDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VisualStoreVersionControllerApi - interface
 * @export
 * @interface VisualStoreVersionControllerApi
 */
export interface VisualStoreVersionControllerApiInterface {
    /**
     * 
     * @summary createVisualStoreVersion
     * @param {VisualStoreVersionInDTO} visualStoreVersionInDTO visualStoreVersionInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualStoreVersionControllerApiInterface
     */
    createVisualStoreVersionUsingPOST(visualStoreVersionInDTO: VisualStoreVersionInDTO, options?: any): AxiosPromise<VisualStoreVersionOutDTO>;

    /**
     * 
     * @summary getVisualStoreVersion
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualStoreVersionControllerApiInterface
     */
    getVisualStoreVersionUsingGET(id: number, options?: any): AxiosPromise<VisualStoreVersionOutDTO>;

    /**
     * 
     * @summary getVisualStoreVersions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualStoreVersionControllerApiInterface
     */
    getVisualStoreVersionsUsingGET(options?: any): AxiosPromise<Array<VisualStoreVersionOutDTO>>;

    /**
     * 
     * @summary updateVisualStoreVersion
     * @param {number} id id
     * @param {VisualStoreVersionUpdateInDTO} visualStoreVersionUpdateInDTO visualStoreVersionUpdateInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualStoreVersionControllerApiInterface
     */
    updateVisualStoreVersionUsingPUT(id: number, visualStoreVersionUpdateInDTO: VisualStoreVersionUpdateInDTO, options?: any): AxiosPromise<VisualStoreVersionOutDTO>;

}

/**
 * VisualStoreVersionControllerApi - object-oriented interface
 * @export
 * @class VisualStoreVersionControllerApi
 * @extends {BaseAPI}
 */
export class VisualStoreVersionControllerApi extends BaseAPI implements VisualStoreVersionControllerApiInterface {
    /**
     * 
     * @summary createVisualStoreVersion
     * @param {VisualStoreVersionInDTO} visualStoreVersionInDTO visualStoreVersionInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualStoreVersionControllerApi
     */
    public createVisualStoreVersionUsingPOST(visualStoreVersionInDTO: VisualStoreVersionInDTO, options?: any) {
        return VisualStoreVersionControllerApiFp(this.configuration).createVisualStoreVersionUsingPOST(visualStoreVersionInDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getVisualStoreVersion
     * @param {number} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualStoreVersionControllerApi
     */
    public getVisualStoreVersionUsingGET(id: number, options?: any) {
        return VisualStoreVersionControllerApiFp(this.configuration).getVisualStoreVersionUsingGET(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary getVisualStoreVersions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualStoreVersionControllerApi
     */
    public getVisualStoreVersionsUsingGET(options?: any) {
        return VisualStoreVersionControllerApiFp(this.configuration).getVisualStoreVersionsUsingGET(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary updateVisualStoreVersion
     * @param {number} id id
     * @param {VisualStoreVersionUpdateInDTO} visualStoreVersionUpdateInDTO visualStoreVersionUpdateInDTO
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VisualStoreVersionControllerApi
     */
    public updateVisualStoreVersionUsingPUT(id: number, visualStoreVersionUpdateInDTO: VisualStoreVersionUpdateInDTO, options?: any) {
        return VisualStoreVersionControllerApiFp(this.configuration).updateVisualStoreVersionUsingPUT(id, visualStoreVersionUpdateInDTO, options).then((request) => request(this.axios, this.basePath));
    }
}


