import SeamlessImmutable from 'seamless-immutable';
import SettingsActionTypesEnum from './model/SettingsActionTypesEnum';
import { SettingsActionsType } from './settings.actions';
import i18next from 'i18next';


export interface ISettingsInterface {
  language: string;
}

const initialState = SeamlessImmutable<ISettingsInterface>({
  language: i18next.language || "en",
});

export default function settingsReducers (state: SeamlessImmutable.ImmutableObject<ISettingsInterface> = initialState, action: SettingsActionsType) {
  switch (action.type) {
    case SettingsActionTypesEnum.SET_LANGUAGE:
      return state.set('language', action.payload);
    default:
      return state;
  }
}
