import { Box, Step, StepLabel, Stepper, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import IDispatchProps from '../../redux/IDispatchProps';
import InstallationDataComponent from '../Onboarding/InstallationData';
import EnviromentConfiguration from '../Onboarding/EnviromentConfiguration';
import InstallationSummary from '../Onboarding/InstallationSummary';
import OnboardingActions from '../Onboarding/OnboardingActions';
import SelectTemplate from '../Onboarding/SelectTemplate';
import _ from 'lodash';
import ReduxLanguage from '../Language/ReduxLanguage';
import { showModal } from '../../redux/modal/modal.actions';
import { ModalTypes } from '../../redux/modal/model/ModalTypes';
import { IState } from '../../redux';
import { OnboardingData } from '../../redux/installation/model/Onboarding';
import * as installationActions from '../../redux/installation/installation.actions';


interface InstallationOnboardingProps extends IDispatchProps {
  onCancelOnboarding: () => void;
  onFinishOnboarding: () => void;
  onboardingData: OnboardingData;
}

interface InstallationOnboardingState {
  activeStep: number;
  isLoadingAction: boolean;
}

class InstallationOnboarding extends React.Component<InstallationOnboardingProps, InstallationOnboardingState> {

  constructor(props: InstallationOnboardingProps) {
    super(props);
    this.state = {
      activeStep: 0,
      isLoadingAction: false
    }
  }
  private steps = [
    {
      id: 0,
      label: 'onboarding.chooseTemplate'
    },
    {
      id: 1,
      label: 'onboarding.envConfiguration'
    },
    {
      id: 2,
      label: 'onboarding.installationData'
    },
    {
      id: 3,
      label: 'onboarding.checkInstallation'
    }
  ];

  private stepsComponent: JSX.Element[] = [
    <SelectTemplate />,
    <EnviromentConfiguration />,
    <InstallationDataComponent />,
    <InstallationSummary />
  ];

  goBack() {
    const { activeStep } = this.state;
    const { onCancelOnboarding, dispatch } = this.props;
    if (activeStep === 0) {
      dispatch<any>(installationActions.InstallationActions.resetOnboardingData());
      onCancelOnboarding();
    }
    this.setState({
      activeStep: activeStep - 1
    });
  }

  goNext() {
    const { activeStep } = this.state;
    const { dispatch } = this.props;

    if (this.steps[activeStep].id === 3) {
      dispatch<any>(showModal('CONFIRMATION_ONBOARDING', {
        modalType: ModalTypes.ALERT_DIALOG,
        modalProps: {
          title: <ReduxLanguage t="confirm" />,
          message: <ReduxLanguage t="onboarding.confirmationOnboardingMessage" />,
          successButtonLabel: <ReduxLanguage t="confirm" />,
          errorButtonLabel: <ReduxLanguage t="back" />,
          onConfirm: () => this.createInstallation(),
        },
      }));
      return;
    }

    this.setState({
      activeStep: activeStep + 1
    });
  }

  download() {
    // TODO: implement
  }

  async createInstallation() {
    const { dispatch, onFinishOnboarding } = this.props;
    try {
      this.setState({ isLoadingAction: true });
      await dispatch<any>(installationActions.createInstallations());
      this.setState({ isLoadingAction: false });
      onFinishOnboarding();
      dispatch<any>(installationActions.InstallationActions.resetOnboardingData());
    } catch (e) {
      this.setState({ isLoadingAction: false });
      dispatch<any>(showModal('CREATE_INSTANCE_ERROR', {
        modalType: ModalTypes.ALERT_DIALOG,
        modalProps: {
          title: <ReduxLanguage t="error" />,
          message: <ReduxLanguage t="installations.createInstallationError" />,
        },
      }));
    }
  }

  isNextButtonDisabled() {
    const { onboardingData } = this.props;
    const { activeStep } = this.state;

    switch (activeStep) {
      case 0:
        return !onboardingData.selectedTemplate;
      case 1:
        return !onboardingData.selectedLocation?.id;
      case 2:
        return !onboardingData.installationData?.name || !onboardingData.installationData?.version || !onboardingData.installationData?.description;
      case 3:
      default:
        return false;
    }

  }

  render() {
    const { activeStep, isLoadingAction } = this.state;

    return (
      <Box
        style={{ minWidth: 500 }}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: 10
          }}
        >
          <Typography variant="h4">
            <ReduxLanguage t="installations.createInstallation" />
          </Typography>
          <Stepper activeStep={activeStep} alternativeLabel style={{ paddingTop: 40 }}>
            {_.map(this.steps, step => (
              <Step key={step.id}>
                <StepLabel>
                  <Typography>
                    <ReduxLanguage t={step.label} />
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        <div style={{ height: 'calc(100% - 230px)' }}>
          {this.stepsComponent[activeStep]}
        </div>
        <Box
          style={{ paddingRight: 20 }}
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center'
          }}
        >
          <OnboardingActions
            onGoBack={() => this.goBack()}
            onGoNext={() => this.goNext()}
            nextLabel={this.steps[activeStep].id === 3 ? "confirm" : "next"}
            disabledNext={this.isNextButtonDisabled()}
            isLoadingAction={isLoadingAction}
          />
        </Box>
      </Box >
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    onboardingData: state.installation.onboardingData
  }
}

export default connect(mapStateToProps)(InstallationOnboarding);
