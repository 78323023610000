import { green, red, yellow } from "@mui/material/colors";
import { InstallationStatusEnum } from "../redux/installation/model/Installations";
import { InstanceServiceStatusOutDTOStatusEnum } from "../repository/vitruvian/client";

export const MAIN_BACKGROUND_COLOR = "rgb(247,247,247)";
export const DRAWER_BACKGROUND_COLOR = "white";

export const APPBAR_BACKGROUND_COLOR = "transparent";

export const BUTTON_BORDER_COLOR = "rgb(220, 220, 220)";

export const PRIMARY_MAIN_COLOR = 'rgb(255,0,18)'

export const INSTALLATION_STATUS_PENDING_COLOR = yellow[600];
export const INSTALLATION_STATUS_ACTIVE_COLOR = green[600];
export const INSTALLATION_STATUS_ERROR_COLOR = red[600];

export function getInstallationStatusColor(status: InstallationStatusEnum) {
  switch (status) {
    case InstallationStatusEnum.ACTIVE: return INSTALLATION_STATUS_ACTIVE_COLOR;
    case InstallationStatusEnum.PENDING: return INSTALLATION_STATUS_PENDING_COLOR;
    case InstallationStatusEnum.ERROR: return INSTALLATION_STATUS_ERROR_COLOR;
    default: return INSTALLATION_STATUS_ERROR_COLOR;
  }
}

export function getServiceStatusColor(status?: InstanceServiceStatusOutDTOStatusEnum) {
  switch (status) {
    case InstanceServiceStatusOutDTOStatusEnum.Ok: return INSTALLATION_STATUS_ACTIVE_COLOR;
    case InstanceServiceStatusOutDTOStatusEnum.Ko: return INSTALLATION_STATUS_ERROR_COLOR;
    default: return INSTALLATION_STATUS_ERROR_COLOR;
  }
}

