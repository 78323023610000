import { LocationOutDTO, TemplateOutDTO } from "../../repository/vitruvian/client";
import _ from 'lodash';

export function formatTemplateName(size: string, enviroment: string, provider: string) {
  return `${_.upperFirst(size)} ${_.upperFirst(enviroment)} ${provider}`;
}

export function formatLocationName(location: LocationOutDTO, template?: TemplateOutDTO) {
  if (template?.providerOutDTO && location.continent) {
    return `${template.providerOutDTO.name} - ${formatContinentName(location.continent)} (${location.name})`;
  }

  return `${location.continent} (${location.name})`;
}

export function formatContinentName(continent: string) {
  return _.replace(continent, "_", " ");
}

export function getLogsFromCreatiobLogs(logs: string): string[] {
  return _.filter(_.split(logs, '\n'), element => element !== "");
}