import { InstallationStatusEnum } from "../../redux/installation/model/Installations";

const enTranslation = {
  or: 'or',
  next: 'Next',
  back: 'Back',
  download: 'Download',
  confirm: 'Confirm',
  error: 'Error',
  ok: 'Ok',
  cancel: 'cancel',
  entepriseProMessage: 'This feature is available in the Enterprise Pro version',
  info: 'Info',
  languages: {
    it: "Italian",
    en: "English",
    ITALIAN: "Italian",
    ENGLISH: "English",
  },
  drawer: {
    home: "Home",
    installation: "Installation",
    billing: "Billing",
    support: 'Support',
    fileManager: 'File Manager',
  },
  login: {
    signIn: 'Sign In',
    signUp: 'Sign Up',
    login: 'Log In',
    username: 'Username',
    password: 'Password',
    forgotPassword: 'Forgot Password?',
    signUpMessage: 'Don\'t have an Account?',
    signInWithGoogle: 'Sign In With Google',
    signInWithMicrosoft: 'Sign In With Microsoft',
    signInWithActiveDirectory: 'Sign In With Active Directory',
    logout: 'Log out',
    loginErrorMessage: 'Login error. Please check your credentials and try again'
  },
  installations: {
    name: 'Name',
    description: 'Description',
    version: 'Version',
    emptyInstallationTitle: 'No installations found',
    emptyInstallationDescription: 'Click on "create an installation" to create a new one',
    createInstallation: 'Create an installation',
    refreshInstallation: 'Refresh',
    exposedServices: "Exposed services",
    enviroment: 'Enviroment',
    visualStoreVersion: 'Version',
    location: 'Location',
    configuration: 'Credentials',
    downloadConfFiles: 'Download configuration files',
    monitoring: 'Monitoring',
    maintenance: 'Maintenance',
    metrics: 'Metrics',
    logs: 'Logs',
    support: 'Support',
    requestTicket: 'Request a ticket',
    billing: 'Billing',
    details: 'Details',
    sla30Days: "SLA in the 30 last days",
    items: 'Items',
    promotions: 'Promotions',
    stores: 'Stores',
    accessCluster: 'Get access to the cluster',
    enabledDemoData: 'Enabled demo data',
    createInstallationError: 'An error occurred during creation. Please, retry',
    author: 'Author',
    created: 'Created',
    menu: {
      activate: 'Activate',
      deactivate: 'Deactivate',
      delete: 'Delete',
      clone: 'Clone',
      exportData: 'Export Data'
    },
    actualCost: 'Actual cost: {{credits}} credits',
    installationCreated: 'Installation succesfully created!',
    status: {
      [InstallationStatusEnum.ACTIVE]: 'Active',
      [InstallationStatusEnum.PENDING]: 'Pending',
      [InstallationStatusEnum.ERROR]: 'Error',
      statusActiveMessage: 'The installation is Active',
      statusPendingMessage: 'The installation is Pending. You will receive an e-mail as soon as the installation is completed.',
      statusErrorMessage: 'It seems that something is wrong with this installation. Please open a ticket to the Vitruvian team.',
    }
  },
  onboarding: {
    chooseTemplate: 'Choose the template',
    envConfiguration: 'Environment configuration',
    installationData: 'Installation data',
    checkInstallation: 'Check your new installation',
    confirmationOnboardingMessage: 'Are you sure you want to create this installation?',
    location: 'Location',
    continent: 'Continent',
  },
  template: {
    emptyTemplateTitle: 'No templates found',
    costHour: 'Cost/hour',
    credits: '{{credits}} credits'
  },
  fileManager: {
    selectBucket: 'Select bucket',
    download: 'Download',
    upload: 'Upload',
    delete: 'Delete',
    downloadFileError: 'An error occurred during download request. Please try again',
    selectedElement: 'Selected element: ',
    deleteElementMessage: 'Are you sure you want to delete the selected element? Click ok to continue',
    deleteElementError: 'An error occurred while deleting the selected element. Please try again',
    uploadFileError: 'An error occurred while uploading the selected file. Please try again'
  }
}

export default enTranslation;