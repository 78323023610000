import React from 'react';
import { MAIN_BACKGROUND_COLOR } from '../../config/style';

const ComponentAppBarWrapper: React.FunctionComponent = props => {
  return (
    <div style={{ height: '100vh', width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: MAIN_BACKGROUND_COLOR }}>
      <div style={{ height: '50px', width: '100%' }} />
      <div style={{ height: 'calc(100% - 50px)' }}>
        {props.children}
      </div>
    </div>
  );
}

export default ComponentAppBarWrapper;