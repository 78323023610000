import { clientId, clientSecret } from "../../../config";
import { UserOutDTO } from "../../../repository/vitruvian/implementation/LoginControllerApi";
import { loginApi } from "../../../repository/vitruvian/vitruvianApi";
import { IAuthService } from "../IAuthService";
import qs from 'query-string';

class AuthService implements IAuthService {

  public async loginUser(username: string, password: string): Promise<UserOutDTO> {
    try {
      const config = {
        auth: {
          username: clientId,
          password: clientSecret,
        },
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      };

      const credentials = {
        grant_type: 'password',
        username,
        password,
      };
      const qsCredentials = qs.stringify(credentials);
      const user = await loginApi.loginUser(qsCredentials, config);
      return user.data;
    } catch (e) {
      throw e;
    }
  }
}

export default new AuthService();