import { IState } from '..';
import { ActionsUnion, createAction, IThunkAction } from '../utils';
import ModalActionTypesEnum from './model/ModalActionTypesEnum';
import ModalDataProps from './model/ModalDataProps';
import ModalProps from './model/ModalProps';

export const ModalActions = {
  setModalData: createAction<typeof ModalActionTypesEnum.SHOW_MODAL, ModalProps<any>>(ModalActionTypesEnum.SHOW_MODAL),
  resetModalData: createAction<typeof ModalActionTypesEnum.HIDE_MODAL, string>(ModalActionTypesEnum.HIDE_MODAL),
}

export function showModal<T>(id: string, data: ModalDataProps<T>): IThunkAction<void, IState> {
  return (dispatch) => dispatch(ModalActions.setModalData({ id, data }));
}

export function hideModal(id: string): IThunkAction<void, IState> {
  return (dispatch) => dispatch(ModalActions.resetModalData(id));
}

export type ModalActionsType = ActionsUnion<typeof ModalActions>;
