import { Box, Divider } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../redux';
import IDispatchProps from '../../redux/IDispatchProps';
import { InstallationActions } from '../../redux/installation/installation.actions';
import { Continent } from '../../redux/installation/model/Installations';
import { OnboardingData } from '../../redux/installation/model/Onboarding';
import { LocationOutDTO } from '../../repository/vitruvian/client';
import { locationApi } from '../../repository/vitruvian/vitruvianApi';
import ReduxLanguage from '../Language/ReduxLanguage';
import LocationList from '../Location/LocationList';

interface EnviromentConfigurationState {
  locations: LocationOutDTO[];
}

interface EnviromentConfigurationProps extends IDispatchProps {
  onboardingData: OnboardingData;
}

export interface IContinent {
  id: Continent;
  name: string | JSX.Element;
}

class EnviromentConfiguration extends React.Component<EnviromentConfigurationProps, EnviromentConfigurationState> {

  constructor(props: EnviromentConfigurationProps) {
    super(props);
    this.state = {
      locations: []
    };
  }
  private continent: IContinent[] = [
    {
      id: "AFRICA",
      name: 'Africa'
    },
    {
      id: "ASIA_PACIFIC",
      name: 'Pacific Asia'
    },
    {
      id: "AUSTRALIA",
      name: 'Australia'
    },
    {
      id: "EUROPE",
      name: 'Europe'
    },
    {
      id: "MIDDLE_EAST",
      name: 'Middle east'
    },
    {
      id: "NORTH_AMERICA",
      name: 'North America'
    },
    {
      id: "SOUTH_AMERICA",
      name: 'South America'
    },
  ]

  componentDidMount() {
    const { onboardingData: { selectedContinent, selectedTemplate } } = this.props;

    if (selectedContinent) {
      if (selectedTemplate?.providerOutDTO?.id != null) {
        this.fetchLocations(selectedContinent, selectedTemplate?.providerOutDTO?.id);
      }
    }
  }

  selectContinent(continent: Continent) {
    const { dispatch, onboardingData } = this.props;
    dispatch(InstallationActions.setOnboardingContinent(continent));
    dispatch(InstallationActions.setOnboardingLocation(undefined));
    if (onboardingData.selectedTemplate?.providerOutDTO?.id == null) {
      throw new Error('Missing Id');
    }
    this.fetchLocations(continent, onboardingData.selectedTemplate.providerOutDTO.id);
  }

  async fetchLocations(continent: Continent, providerId: number) {
    try {
      const locations = await locationApi.getLocationsUsingGET(continent, providerId);
      this.setState({
        locations: locations.data
      });
    } catch (e) {
      //TODO: handle error
    }
  }

  selectLocation(location: LocationOutDTO) {
    const { dispatch } = this.props;
    dispatch(InstallationActions.setOnboardingLocation(location));
  }
  render() {
    const { onboardingData: { selectedLocation, selectedContinent } } = this.props;
    const { locations } = this.state;

    return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'row', width: '60%', height: '80%' }}>
          <div style={{ width: '30%', minWidth: 170 }}>
            <LocationList
              title={<ReduxLanguage t="onboarding.continent" />}
              locations={this.continent}
              onSelectLocation={(l) => this.selectContinent(l.id as Continent)}
              selectedLocation={selectedContinent || ""}
              style={{ overflow: "scroll", height: 'calc(100% - 10px)' }}
            />
          </div>
          <React.Fragment>
            <div style={{ width: '10%', display: 'flex', justifyContent: 'center' }}>
              {selectedContinent ?
                <Divider
                  orientation="vertical"
                  style={{ height: "100%" }}
                /> : null}
            </div>
            <Box sx={{ width: '60%', minWidth: 150 }}>
              {selectedContinent ?
                <LocationList
                  title={<ReduxLanguage t="onboarding.location" />}
                  locations={locations}
                  onSelectLocation={(l) => this.selectLocation(l as LocationOutDTO)}
                  selectedLocation={selectedLocation?.id || ""}
                  style={{ overflow: "scroll", height: 'calc(100% - 10px)' }}
                />
                :
                null
              }
            </Box>
          </React.Fragment>
        </Box>
      </Box>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    onboardingData: state.installation.onboardingData
  }
}

export default connect(mapStateToProps)(EnviromentConfiguration);