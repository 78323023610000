import { Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../redux';
import IDispatchProps from '../../redux/IDispatchProps';
import { InstallationActions } from '../../redux/installation/installation.actions';
import { OnboardingData } from '../../redux/installation/model/Onboarding';
import { Template } from '../../redux/template/model/Template';
import * as templateActions from '../../redux/template/template.actions';
import { ITemplateInterface } from '../../redux/template/template.reducers';
import ReduxLanguage from '../Language/ReduxLanguage';
import TemplateList from '../Template/TemplateList';

interface SelectTemplateProps extends IDispatchProps {
  templateState: ITemplateInterface;
  onboardingData: OnboardingData;
}

interface SelectTemplateState { }
class SelectTemplate extends React.Component<SelectTemplateProps, SelectTemplateState> {

  async componentDidMount() {
    const { dispatch } = this.props;
    try {
      await dispatch<any>(templateActions.fetchTemplates());
    } catch (e) {
      // TODO: ignore
    }

  }

  selectTemplate(template: Template) {
    const { dispatch } = this.props;

    dispatch<any>(InstallationActions.setOnboardingTemplate(template));
  }
  render() {
    const { templateState, onboardingData } = this.props;

    return (
      <div style={{ height: 'calc(100% - 80px)', overflow: 'scroll', padding: 40 }}>
        {templateState.templates.length ?
          <TemplateList
            templates={templateState.templates}
            selectedTemplate={onboardingData.selectedTemplate}
            onSelectTemplate={(t) => this.selectTemplate(t)}
          /> :
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
            <Typography variant="h4" color="text.secondary" fontWeight="bold" align="center">
              <ReduxLanguage t="template.emptyTemplateTitle" />
            </Typography>
          </div>
        }
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    templateState: state.template,
    onboardingData: state.installation.onboardingData,
  }
}

export default connect(mapStateToProps)(SelectTemplate);
