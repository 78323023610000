import { Link, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { LocationOutDTO } from '../../repository/vitruvian/client';
import { IContinent } from '../Onboarding/EnviromentConfiguration';

interface LocationCardProps {
  onClick: (l: IContinent | LocationOutDTO) => void;
  selectedLocation: string | number;
  location: IContinent | LocationOutDTO;
}

const LocationCard: React.FunctionComponent<LocationCardProps> = props => {
  return (
    <Link underline="none" component="button" onClick={() => props.onClick(props.location)} style={{ width: '100%' }}>
      <Box
        sx={{
          border: 4,
          borderColor: props.selectedLocation === props.location.id ? "red" : "transparent"
        }}
      >
        <Paper style={{ height: 60 }}>
          <Typography variant="h5" sx={{ verticalAlign: "center", display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }} noWrap>
            {props.location.name}
          </Typography>
        </Paper>
      </Box>
    </Link>
  );
}

export default LocationCard;