import { Link, Typography } from '@mui/material';
import React from 'react';

interface ButtonLinkProps {
  label: string | React.ReactElement;
  fontSize?: number;
  labelAlignment?: "right" | "left" | "inherit" | "center" | "justify";
  labelFontWeight?: number | string;
  labelUnderline?: "none" | "hover" | "always"
  labelColor?: string;
  onClick?: () => void;
  style?: React.CSSProperties | undefined
}

const ButtonLink: React.FunctionComponent<ButtonLinkProps> = props => {
  const {
    label,
    fontSize,
    labelAlignment,
    labelFontWeight,
    labelUnderline,
    labelColor,
    onClick,
    style
  } = props;

  return (
    <Link
      component="button"
      color={labelColor}
      underline={labelUnderline}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Typography
        align={labelAlignment}
        fontWeight={labelFontWeight}
        fontSize={fontSize}
        style={style}
      >
        {label}
      </Typography>
    </Link>
  );
}

export default ButtonLink;