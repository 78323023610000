import SeamlessImmutable from 'seamless-immutable';
import InstallationActionTypesEnum from './model/InstallationActionTypesEnum';
import { InstallationActionsType } from './installation.actions';
import { OnboardingData } from './model/Onboarding';
import { Installation, Installations } from './model/Installations';

export interface IInstallationInterface {
  installations: Installations;
  onboardingData: OnboardingData;
  selectedInstallation?: Installation;
}

const initialState = SeamlessImmutable<IInstallationInterface>({
  installations: {},
  onboardingData: {},
  selectedInstallation: undefined,
});

export default function installationReducers(state: SeamlessImmutable.ImmutableObject<IInstallationInterface> = initialState, action: InstallationActionsType) {
  switch (action.type) {
    case InstallationActionTypesEnum.SET_INSTALLATIONS:
      return state.set("installations", action.payload);
    case InstallationActionTypesEnum.SAVE_INSTALLATION_BY_ID:
      return state.setIn(["installations", "content", action.payload.index.toString()], action.payload.installation);
    case InstallationActionTypesEnum.SET_ONBOARDING_DATA_TEMPLATE:
      return state.setIn(["onboardingData", "selectedTemplate"], action.payload);
    case InstallationActionTypesEnum.SET_ONBOARDING_DATA_LOCATION:
      return state.setIn(["onboardingData", "selectedLocation"], action.payload);
    case InstallationActionTypesEnum.SET_ONBOARDING_DATA_CONTINENT:
      return state.setIn(["onboardingData", "selectedContinent"], action.payload);
    case InstallationActionTypesEnum.SET_ONBOARDING_DATA_INSTALLATION_DATA:
      return state.setIn(["onboardingData", "installationData", action.payload.key], action.payload.value);
    case InstallationActionTypesEnum.RESET_ONBOARDING_DATA:
      return state.set("onboardingData", {});
    case InstallationActionTypesEnum.SET_SELECTED_INSTALLATION:
      return state.set("selectedInstallation", action.payload);
    default:
      return state;
  }
}
