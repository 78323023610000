import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../redux';
import translations from "../../translations/i18next";

interface IReduxLanguageProps {
  language: string;
  t: string;
  languageProps?: any;
}

const ReduxLanguage: React.FunctionComponent<IReduxLanguageProps> = props => {
  // eslint-disable-next-line
  const { t: languageKey, languageProps, language } = props;
  return <>{translations.t(languageKey, languageProps)}</>;
};

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language
  };
}

export default connect(mapStateToProps)(ReduxLanguage);
