import { Box, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../redux';
import IDispatchProps from '../../redux/IDispatchProps';
import { OnboardingData } from '../../redux/installation/model/Onboarding';
import { formatLocationName, formatTemplateName } from '../../utils/installations';
import InstallationForm from '../Forms/InstallationForm';
import ReduxLanguage from '../Language/ReduxLanguage';

interface InstallationDataProps extends IDispatchProps {
  onboardingData?: OnboardingData;
}

interface InstallationDataState { }
class InstallationDataComponent extends React.Component<InstallationDataProps, InstallationDataState> {
  render() {
    const { onboardingData } = this.props;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'center', overflow: 'scroll' }}>
        <div style={{ marginLeft: 100 }}>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {onboardingData?.selectedTemplate ?
              <div style={{ display: 'flex', flexWrap: 'wrap', marginRight: 10 }}>
                <Typography variant="h6" fontWeight="light">
                  Template:&nbsp;
                </Typography>
                <Typography variant="h6">
                  {formatTemplateName(onboardingData?.selectedTemplate.size || "", onboardingData?.selectedTemplate.environment || "", onboardingData?.selectedTemplate.providerOutDTO?.name || "",)}
                </Typography>
              </div> :
              null
            }
            {onboardingData?.selectedLocation ?
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <Typography variant="h6" fontWeight="light" noWrap>
                  <ReduxLanguage t='onboarding.envConfiguration' />:&nbsp;
                </Typography>
                <Typography variant="h6">
                  {formatLocationName(onboardingData.selectedLocation, onboardingData.selectedTemplate)}
                </Typography>
              </div> :
              null
            }
          </div>
          <div style={{ width: '50%', paddingTop: 20, minWidth: 200 }}>
            <InstallationForm />
          </div>
        </div>
      </Box>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    onboardingData: state.installation.onboardingData
  }
}

export default connect(mapStateToProps)(InstallationDataComponent);
