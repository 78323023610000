import FileManagerActionTypesEnum from './model/FileManagerActionTypesEnum';
import { createAction, ActionsUnion, IThunkAsyncAction } from '../utils';
import { IState } from '..';
import { IListObject } from './model/FileManager';
import _ from 'lodash';
import { storageApi } from '../../repository/vitruvian/vitruvianApi';
import { PresignedUrlDTO } from '../../repository/vitruvian/client';
import { createAxios } from '../../repository/utils/axios';
import { isObjectKeyFolder } from '../../utils/fileManager/FileManagerUtils';

export const FileManagerActions = {
  setBuckets: createAction<typeof FileManagerActionTypesEnum.SET_BUCKETS, string[]>(FileManagerActionTypesEnum.SET_BUCKETS),
  setSelectedBucket: createAction<typeof FileManagerActionTypesEnum.SET_SELECTED_BUCKET, string>(FileManagerActionTypesEnum.SET_SELECTED_BUCKET),
  setListObjects: createAction<typeof FileManagerActionTypesEnum.SET_LIST_OBJECTS, IListObject[]>(FileManagerActionTypesEnum.SET_LIST_OBJECTS),
  setSelectedListObject: createAction<typeof FileManagerActionTypesEnum.SET_SELECTED_LIST_OBJECTS, IListObject | undefined>(FileManagerActionTypesEnum.SET_SELECTED_LIST_OBJECTS),
};

export function fetchBuckets(): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const buckets = await storageApi.getBucketsListUsingGET();
      if (buckets.data) {
        dispatch(FileManagerActions.setBuckets(_.filter(buckets.data, e => _.includes(e, "vitruvian-demo"))));
      }
    } catch (e) {
      throw e;
    }
  };
}

export function fetchListObjects(bucket: string): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const listObj = await storageApi.getObjectsListUsingGET(bucket);
      dispatch(FileManagerActions.setListObjects(listObj.data));
    } catch (e) {
      throw e;
    }
  };
}

export function downloadFile(bucket: string, objectKey: string): IThunkAsyncAction<PresignedUrlDTO, IState> {
  return async (dispatch, getState) => {
    try {
      const downloadSignedUrl = await storageApi.getDownloadSignedUrlUsingGET(bucket, objectKey);
      return downloadSignedUrl.data;
    } catch (e) {
      throw e;
    }
  };
}

export function uploadFile(bucket: string, objectKey: string, file: File): IThunkAsyncAction<PresignedUrlDTO, IState> {
  return async (dispatch, getState) => {
    try {
      let path = objectKey;
      if (objectKey && !isObjectKeyFolder(objectKey)) {
        path = objectKey.substring(0, _.lastIndexOf(objectKey, '/')) + "/";
      }
      const uploadSignedUrl = await storageApi.getUploadSignedUrlUsingGET(bucket, path + file.name);

      const config = {
        headers: {
          'Content-Type': file.type || "application/octet-stream"
        }
      }

      const axios = createAxios();

      await axios.put(uploadSignedUrl.data.presignedUrl!, file, config);

      return uploadSignedUrl.data;
    } catch (e) {
      throw e;
    }
  };
}

export function deleteFile(bucket: string, objectKey: string): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      await storageApi.deleteObjectUsingDELETE(bucket, objectKey);
    } catch (e) {
      throw e;
    }
  };
}

export type FileManagerActionsType = ActionsUnion<typeof FileManagerActions>;
