import { push } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import IDispatchProps from '../../redux/IDispatchProps';
import { User } from '../../redux/users/model/Users';
import * as usersActions from '../../redux/users/users.actions';
import { AppRouterPath } from '../../routes/AppRouter';

interface AuthComponentProps extends IDispatchProps {
  children: React.ReactNode;
}

interface AuthComponentState {
  isAuth: boolean;
}

class AuthComponent extends React.Component<AuthComponentProps, AuthComponentState> {

  constructor(props: AuthComponentProps) {
    super(props);
    this.state = {
      isAuth: false,
    };
  }

  async componentDidMount() {
    const { dispatch } = this.props;

    try {
      const user: User = await dispatch<any>(usersActions.getLoginInfoFromStorage());
      if (!user.isAuthenticated) {
        this.setIsAuthenticated(false);
        dispatch(push(AppRouterPath.LOGIN));
      }
      this.setIsAuthenticated(true);
    } catch (e) {
      this.setIsAuthenticated(false);
      dispatch(push(AppRouterPath.LOGIN));
    }
  }

  setIsAuthenticated(isAuth: boolean) {
    this.setState({
      isAuth: isAuth
    });
  }

  render() {
    const { isAuth } = this.state;

    return (
      <React.Fragment>
        {isAuth ? this.props.children : null}
      </React.Fragment>
    );
  }
}

export default connect()(AuthComponent);