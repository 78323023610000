import React from 'react';
import { getServiceStatusColor } from '../../config/style';
import { InstanceServiceStatusOutDTOStatusEnum } from '../../repository/vitruvian/client';

interface InstallationStatusProps {
  status?: InstanceServiceStatusOutDTOStatusEnum;
}

const ServiceStatus: React.FunctionComponent<InstallationStatusProps> = props => {
  return (
    <div
      style={{
        width: 15,
        height: 15,
        borderRadius: 7.5,
        backgroundColor: getServiceStatusColor(props.status)
      }}
    />
  );
}

export default ServiceStatus;
