import React from 'react';
import FileBrowser, { FileBrowserFolder } from 'react-keyed-file-browser';
import { Box } from '@mui/system';
import { Button, Typography } from '@mui/material';
import ReduxLanguage from '../Language/ReduxLanguage';
import { IListObject } from '../../redux/fileManager/model/FileManager';
import { convertFileBrowserFileToListObject, convertListObjectToFileManagerObject, isObjectKeyFolder } from '../../utils/fileManager/FileManagerUtils';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import { LoadingButton } from '@mui/lab';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined';

interface FileManagerComponentProps {
  listObjects: IListObject[];
  onSelectElement: (e: IListObject) => void;
  selectedListObject?: IListObject;
  onDownload: () => void;
  onUpload: (file: File) => void;
  onDelete: () => void;
  isUploadingFile: boolean;
}
interface FileManagerComponentState { }

class FileManagerComponent extends React.Component<FileManagerComponentProps, FileManagerComponentState> {
  private inputTextRef: HTMLInputElement | undefined | null;

  openFileBrowserDialog(): void {
    if (this.inputTextRef)
      (this.inputTextRef as any).click();
  }

  onSelectFileFromBrowserFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) return;

    const file = e.target.files[0];
    console.log(file)
    this.props.onUpload(file);
  }

  render() {
    const { listObjects, onSelectElement, selectedListObject, onDownload, onDelete, isUploadingFile } = this.props;

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: 2, p: 1, height: 'calc(100% - 200px)' }}>
        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap' }}>
          <div style={{ display: 'flex', flexDirection: 'row', visibility: selectedListObject ? "visible" : "hidden" }}>
            <Typography variant="body1" color="text.secondary">
              <ReduxLanguage t='fileManager.selectedElement' />&nbsp;
            </Typography>
            <Typography variant="body1" color="text.secondary" fontWeight="bold">
              {selectedListObject?.key}
            </Typography>
          </div>
          <div>
            <Button
              variant="contained"
              onClick={() => onDownload()}
              disabled={!selectedListObject || isObjectKeyFolder(selectedListObject.key!)}
              startIcon={<CloudDownloadOutlinedIcon />}
            >
              <ReduxLanguage t="fileManager.download" />
            </Button>
            <Button
              variant="contained"
              onClick={() => onDelete()}
              style={{ marginLeft: 20 }}
              disabled={!selectedListObject || isObjectKeyFolder(selectedListObject.key!)}
              startIcon={<DeleteOutlineOutlinedIcon />}
            >
              <ReduxLanguage t="fileManager.delete" />
            </Button>
            <LoadingButton
              variant="contained"
              onClick={() => this.openFileBrowserDialog()}
              style={{ marginLeft: 20 }}
              loading={isUploadingFile}
              loadingPosition="start"
              startIcon={<CloudUploadOutlinedIcon />}
            >
              <ReduxLanguage t="fileManager.upload" />
            </LoadingButton>
          </div>
          <input
            style={{ display: "none" }}
            ref={r => this.inputTextRef = r}
            onChange={(e) => this.onSelectFileFromBrowserFile(e)}
            type="file"
            value=""
          />
        </div>
        <div style={{ height: 'calc(100% - 1px)', overflow: 'scroll', marginTop: 20 }}>
          <FileBrowser
            icons={{
              File: <InsertDriveFileOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              Folder: <FolderOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              FolderOpen: <FolderOpenOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              PDF: <PictureAsPdfIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              Image: <ImageOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              Video: <InsertDriveFileOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              Audio: <InsertDriveFileOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              Archive: <Inventory2OutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              Word: <InsertDriveFileOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              Excel: <InsertDriveFileOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              PowerPoint: <InsertDriveFileOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              Text: <InsertDriveFileOutlinedIcon fontSize="small" style={{ marginRight: 10, alignItems: 'center' }} color="action" />,
              Rename: <></>,
              Delete: <></>,
              Loading: <></>,
              Download: <></>,
            }}
            files={convertListObjectToFileManagerObject(listObjects)}
            onSelect={(e: FileBrowserFolder) => onSelectElement(convertFileBrowserFileToListObject(e))}
            canFilter={false}
            detailRenderer={() => <></>}
            renderStyle="table"
          />
        </div>
      </Box>
    );
  }
}

export default FileManagerComponent;