import React from 'react';
import { Route } from 'react-router-dom';
import { Redirect } from 'react-router';
import _ from 'lodash';
import LoginPage from './views/LoginPage';
import { Box } from '@mui/material';
import VitruvianAppBar from '../components/Appbar/VitruvianAppBar';
import VitruvianDrawer from '../components/Drawer/VitruvianDrawer';
import { history, IState } from '../redux';
import { isAuthRoute } from '../utils/router';
import { connect } from 'react-redux';
import { ConnectedRouter, push, RouterState } from 'connected-react-router';
import InstallationsPage from './views/InstallationsPage';
import BaseModal from '../components/Modals/BaseModal';
import AuthComponent from '../components/Auth/AuthComponent';
import * as settingsActions from '../redux/settings/settings.actions';
import IDispatchProps from '../redux/IDispatchProps';
import { setVitruvianRequestInterceptor, setVitruvianResponseInterceptor } from '../repository/vitruvian/vitruvianApi';
import storageService from '../services/storage/impl/storageService';
import StorageKeys from '../services/storage/model/StorageKeys';
import * as usersActions from '../redux/users/users.actions';
import ConfigurationComponent from '../components/Auth/ConfigurationComponent';
import FileManagerPage from './views/FileManagerPage';

export enum AppRouterPath {
  LOGIN = "/login",
  INSTALLATIONS = "/installations",
  FILE_MANAGER = "/fileManager"
}
interface AppRouterProps extends IDispatchProps {
  router: RouterState;
}
interface AppRouterState { }

interface AppRoute {
  key: string;
  path: string;
  component: React.ReactElement;
}

class AppRouter extends React.Component<AppRouterProps, AppRouterState> {
  private routes: AppRoute[] = [
    {
      key: 'login',
      component: <LoginPage />,
      path: AppRouterPath.LOGIN,
    },
    {
      key: 'installations',
      component: <AuthComponent><InstallationsPage /></AuthComponent>,
      path: AppRouterPath.INSTALLATIONS,
    },
    {
      key: 'fileManager',
      component: <AuthComponent><FileManagerPage /></AuthComponent>,
      path: AppRouterPath.FILE_MANAGER,
    }
  ];
  async componentDidMount() {
    const { dispatch } = this.props;
    this.setVitruvianInterceptors();
    await dispatch<any>(settingsActions.fetchSettingsFromStorage());
  }

  setVitruvianInterceptors() {
    setVitruvianRequestInterceptor(async (config) => {
      const token = await storageService.getItem(StorageKeys.AUTH_TOKEN);
      config.headers = { ...config.headers, 'Authorization': `Bearer ${token}` };
      return config;
    });

    setVitruvianResponseInterceptor((error) => {
      return new Promise(async (resolve, reject) => {
        const { response } = error;
        if (response && (response.status === 401)) {
          this.props.dispatch<any>(usersActions.logoutUser());
          this.props.dispatch(push(AppRouterPath.LOGIN));
        } else {
          reject(error);
        }
      });
    });
  }

  public render() {
    const { router } = this.props;
    return (
      <ConfigurationComponent>
        <React.Fragment>
          <BaseModal />
          <Box sx={{ display: 'flex' }}>
            <VitruvianAppBar routePath={router.location?.pathname || ""} />
            {
              !isAuthRoute(router.location?.pathname) ?
                <VitruvianDrawer />
                : null
            }
            <ConnectedRouter history={history}>
              <Route exact path="/">
                <Redirect to={AppRouterPath.INSTALLATIONS} />
              </Route>
              {_.map(this.routes, e => <Route exact key={e.key} path={e.path}>{e.component}</Route>)}
            </ConnectedRouter>
          </Box>
        </React.Fragment>
      </ConfigurationComponent>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    router: state.router
  }
}

export default connect(mapStateToProps)(AppRouter);
