import { Box } from '@mui/material';
import React from 'react';
import googleImage from '../../assets/Images/google.png';
import microsoftImage from '../../assets/Images/MicrosoftIcon.png';
import activeDirectoryIcon from '../../assets/Images/ActiveDirectoryIcon.png';
import SignInCloudButton from './SignInCloudButton';
import IDispatchProps from '../../redux/IDispatchProps';
import { connect } from 'react-redux';
import { showModal } from '../../redux/modal/modal.actions';
import ReduxLanguage from '../Language/ReduxLanguage';
import { ModalTypes } from '../../redux/modal/model/ModalTypes';

export interface CloudLoginProps extends IDispatchProps {

}

class CloudLogin extends React.Component<CloudLoginProps> {

  cloudLogin() {
    const { dispatch } = this.props;
    dispatch<any>(showModal('CLOUD_LOGIN_INFO', {
      modalType: ModalTypes.ALERT_DIALOG,
      modalProps: {
        title: <ReduxLanguage t="info" />,
        message: <ReduxLanguage t="entepriseProMessage" />,
      },
    }));
  }

  render() {
    return (
      <Box
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'center'
        }}
      >
        <SignInCloudButton
          title="login.signInWithGoogle"
          imgPath={googleImage}
          onClick={() => this.cloudLogin()}
        />
        <SignInCloudButton
          title="login.signInWithMicrosoft"
          imgPath={microsoftImage}
          onClick={() => this.cloudLogin()}
        />
        <SignInCloudButton
          title="login.signInWithActiveDirectory"
          imgPath={activeDirectoryIcon}
          onClick={() => this.cloudLogin()}
        />
      </Box>
    );
  }
}

export default connect()(CloudLogin);