import { Box, Typography } from '@mui/material';
import React from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

interface UserAvatarProps {
  username: string;
}
interface UserAvatarState { }
class UserAvatar extends React.Component<UserAvatarProps, UserAvatarState> {
  render() {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Typography color="black">
          {this.props.username}
        </Typography>
        <div style={{ marginLeft: 10 }}>
          <AccountCircleIcon sx={{ color: "lightgray" }} fontSize="large" />
        </div>

      </Box>
    );
  }
}

export default UserAvatar;