import { InstallationStatusEnum } from "../../redux/installation/model/Installations";

const enTranslation = {
  or: 'o',
  next: 'Avanti',
  back: 'Indietro',
  download: 'Download',
  confirm: 'Conferma',
  error: 'Errore',
  ok: 'Ok',
  cancel: 'Cancella',
  entepriseProMessage: 'Questa funzionalità è disponibile nella versione Enterprise Pro',
  info: 'Info',
  languages: {
    it: "Italiano",
    en: "Inglese",
    ITALIAN: "Italiano",
    ENGLISH: "Inglese",
  },
  drawer: {
    home: "Home",
    installation: "Installazione",
    billing: "Fatturazione",
    support: 'Supporto',
    fileManager: 'File Manager',
  },
  login: {
    signIn: 'Accedi',
    signUp: 'Iscriviti',
    login: 'Log In',
    username: 'Username',
    password: 'Password',
    forgotPassword: 'Password dimenticata?',
    signUpMessage: 'Non hai un account?',
    signInWithGoogle: 'Accedi con Google',
    signInWithMicrosoft: 'Accedi con Microsoft',
    signInWithActiveDirectory: 'Accedi con Active Directory',
    logout: 'Esci',
    loginErrorMessage: 'Login fallito. Per favore controlla le tue credenziali e riprova',
  },
  installations: {
    name: 'Nome',
    description: 'Descrizione',
    version: 'Versione',
    emptyInstallationTitle: 'Nessuna installazione trovata',
    emptyInstallationDescription: 'Clicca su "crea un\'installazione" per crearne una nuova',
    createInstallation: 'Crea un\'installazione',
    refreshInstallation: 'Aggiorna',
    exposedServices: "Servizi esposti",
    enviroment: 'Ambiente',
    visualStoreVersion: 'Versione',
    location: 'Posizione',
    configuration: 'Credenziali',
    downloadConfFiles: 'Scarica i file di configurazione',
    monitoring: 'Monitoraggio',
    maintenance: 'Manutenzione',
    metrics: 'Metriche',
    logs: 'Logs',
    support: 'Supporto',
    requestTicket: 'Apri un ticket', // TODO
    billing: 'Fatturazione',
    details: 'Dettagli',
    sla30Days: "SLA negli ultimi 30 giorni",
    items: 'Items',
    promotions: 'Promotions',
    stores: 'Stores',
    accessCluster: 'Get access to the cluster',
    enabledDemoData: 'Abilita dati demo',
    createInstallationError: 'Si è verificato un errore durante la creazione. Per favore, riprova',
    author: 'Autore',
    created: 'Data di creazione',
    menu: {
      activate: 'Attiva',
      deactivate: 'Disattiva',
      delete: 'Cancella',
      clone: 'Clona',
      exportData: 'Esporta Data'
    },
    actualCost: 'Costo attuale: {{credits}} crediti',
    installationCreated: 'Installazione creata con successo!',
    status: {
      [InstallationStatusEnum.ACTIVE]: 'Attiva',
      [InstallationStatusEnum.PENDING]: 'In Attesa',
      [InstallationStatusEnum.ERROR]: 'Errore',
      statusActiveMessage: 'L\'installazione è attiva',
      statusPendingMessage: 'L\'installazione è in attesa. Riceverai una mail quando l\'installazione sarà completata.',
      statusErrorMessage: 'Sembra che ci siano problemi con questa installazione. Per favore, apri un ticket al team di Vitruvian.',
    }
  },
  onboarding: {
    chooseTemplate: 'Scegli il template',
    envConfiguration: 'Configurazione ambiente',
    installationData: 'Dati installazione',
    checkInstallation: 'Controlla la tua nuova installazione',
    confirmationOnboardingMessage: 'Sei sicuro di voler creare questa installazione?',
    location: 'Posizione',
    continent: 'Continente',
  },
  template: {
    emptyTemplateTitle: 'Nessun template trovato',
    costHour: 'Costo/ora',
    credits: '{{credits}} crediti'
  },
  fileManager: {
    selectBucket: 'Scegli bucket',
    download: 'Download',
    upload: 'Upload',
    delete: 'Cancella',
    downloadFileError: 'Si è verificato un errore durante la richiesta di download. Per favore, riprova',
    selectedElement: 'Elemento selezionato: ',
    deleteElementMessage: 'Sei sicuro di voler eliminare l\'elemento selezionato? Premi ok per continuare',
    deleteElementError: 'Si è verificato un errore durante la cancellazione. Per favore riprova',
    uploadFileError: 'Si è verificato un errore durante l\'upload del file selezionato. Per favore riprova'
  }
}

export default enTranslation;