import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import TemplateCard from './TemplateCard';
import { Template } from '../../redux/template/model/Template';
import { CircularProgress } from '@mui/material';

interface TemplateListProps {
  templates: Template[];
  selectedTemplate?: Template;

  onSelectTemplate: (t: Template) => void;
}

class TemplateList extends React.Component<TemplateListProps> {
  render() {
    const { selectedTemplate, templates, onSelectTemplate } = this.props;

    return (
      <InfiniteScroll
        pageStart={0}
        initialLoad={true}
        loadMore={() => { }}
        hasMore={false}
        loader={<CircularProgress />}
        useWindow={false}
      >
        <div style={{ maxWidth: '100%', flexWrap: 'wrap', display: 'flex', alignItems: 'center', padding: 5 }}>
          {_.map(templates, template =>
            <div key={template.id} style={{ padding: 15 }}>
              <TemplateCard
                key={template.id}
                template={template}
                onSelectTemplate={(t) => onSelectTemplate(t)}
                selectedTemplateId={selectedTemplate?.id}
              />
            </div>
          )}
        </div>
      </InfiniteScroll>
    );
  }
}

export default TemplateList;
