import { Grid, Typography, Paper } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { InstanceDetailsOutDTO, InstanceServiceStatusOutDTOStatusEnum } from '../../repository/vitruvian/client';
import _ from 'lodash';
import ServiceStatus from './ServiceStatus';
import InstallationDetails from './InstallationDetails';

interface InstallationDetailsGraphProps {
  installationsDetails: InstanceDetailsOutDTO;
}

interface InstallationDetailsGraphState { }

class InstallationDetailsGraph extends React.Component<InstallationDetailsGraphProps, InstallationDetailsGraphState> {
  render() {
    const { installationsDetails } = this.props;

    let installationGraph = installationsDetails?.instanceServicesStatus;

    if (_.includes(_.lowerCase(installationsDetails.templateOutDTO?.size), "status page")) {
      installationGraph = [
        { serviceName: "Status Page", status: InstanceServiceStatusOutDTOStatusEnum.Ok },
        { serviceName: "Grafana", status: InstanceServiceStatusOutDTOStatusEnum.Ok },
        { serviceName: "Loki", status: InstanceServiceStatusOutDTOStatusEnum.Ok }
      ];
    }

    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-around',
          alignItems: 'center',
          height: '100%'
        }}
      >
        {
          installationsDetails && installationGraph ?
            <Grid container spacing={2} alignItems="center" justifyContent="center" style={{ width: "80%" }}>
              {
                _.map(installationGraph, service => (
                  <Grid key={service.serviceName} item xs={6} justifyContent="center" alignItems="center" alignSelf={"center"}>
                    <Paper elevation={3} sx={{ minHeight: 150 }}>
                      <div style={{ display: 'flex', justifyContent: 'flex-end', padding: 15 }}>
                        <ServiceStatus status={service.status} />
                      </div>
                      <Typography color="text.secondary" variant="h5" align="center" style={{ padding: 20 }} noWrap>{service.serviceName}</Typography>
                    </Paper>
                  </Grid>
                ))
              }
            </Grid > :
            null
        }
        <div style={{ width: '65%', }}>
          {/* <Typography fontWeight="light" variant="h6" align="center">
            <ReduxLanguage t="installations.sla30Days" />
          </Typography>
          <Typography fontWeight="bold" variant="h5" align="center">
            99,99%
          </Typography> 
          <Typography fontWeight="light" variant="h6" align="center" style={{ marginTop: 25 }}>
            KPI
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <Typography fontWeight="bold" variant="h6">
                <ReduxLanguage t="installations.items" />
              </Typography>
              <Typography fontWeight="bold" variant="h5" align="center">
                300
              </Typography>
            </div>
            <div>
              <Typography fontWeight="bold" variant="h6">
                <ReduxLanguage t="installations.promotions" />
              </Typography>
              <Typography fontWeight="bold" variant="h5" align="center">
                400
              </Typography>
            </div>
            <div>
              <Typography fontWeight="bold" variant="h6">
                <ReduxLanguage t="installations.stores" />
              </Typography>
              <Typography fontWeight="bold" variant="h5" align="center">
                500
              </Typography>
            </div>
          </div>*/}
        </div>
      </Box >
    );
  }

}

export default InstallationDetailsGraph;