import SettingsActionTypesEnum from './model/SettingsActionTypesEnum';
import { createAction, ActionsUnion, IThunkAction, IThunkAsyncAction } from '../utils';
import { setLanguageWithMoment } from '../../translations/utils';
import { IState } from '..';
import storageService from '../../services/storage/impl/storageService';
import StorageKeys from '../../services/storage/model/StorageKeys';
import { detectBrowserLanguage } from '../../utils/language';

export const SettingsActions = {
  setLanguage: createAction<typeof SettingsActionTypesEnum.SET_LANGUAGE, string>(SettingsActionTypesEnum.SET_LANGUAGE),
};

export function saveLanguageSettings(language: string): IThunkAction<void, IState> {
  return (dispatch, getState) => {
    setLanguageWithMoment(language);
    dispatch(SettingsActions.setLanguage(language));
    storageService.setItem(StorageKeys.LANGUAGE, language);
  };
}

export function fetchSettingsFromStorage(): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    const lang = await storageService.getOrDefaultItem(StorageKeys.LANGUAGE, detectBrowserLanguage());
    setLanguageWithMoment(lang);
    dispatch(SettingsActions.setLanguage(lang));
  };
}
export type SettingsActionsType = ActionsUnion<typeof SettingsActions>;
