import { Link, Menu, MenuItem } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../redux';
import IDispatchProps from '../../redux/IDispatchProps';
import { LANGUAGE_DETAILS } from '../../redux/settings/model/Language';
import * as settingsActions from '../../redux/settings/settings.actions';
import MuiAvatar from '../Avatar/MuiAvatar';
import ReduxLanguage from './ReduxLanguage';

interface LanguageSelectorProps extends IDispatchProps {
  language: string;
}
interface LanguageSelectorState {
  anchorEl?: HTMLElement;
  selectedIndex: number;
}


class LanguageSelector extends React.Component<LanguageSelectorProps, LanguageSelectorState> {
  constructor(props: LanguageSelectorProps) {
    super(props);

    this.state = {
      anchorEl: undefined,
      selectedIndex: 0,
    };
  }

  handleClickListItem(event: React.MouseEvent<HTMLElement>) {
    this.setState({
      anchorEl: event.currentTarget
    });
  }
  handleMenuItemClick(event: React.MouseEvent<HTMLElement>, index: number) {
    const { dispatch } = this.props;
    this.setState({
      selectedIndex: index,
      anchorEl: undefined
    });

    dispatch<any>(settingsActions.saveLanguageSettings(LANGUAGE_DETAILS[index].lang));
  }

  handleClose() {
    this.setState({
      anchorEl: undefined
    });
  };
  render() {
    const { anchorEl } = this.state;
    const { language } = this.props;

    const open = Boolean(anchorEl);

    return (
      <React.Fragment>
        <Link
          color="black"
          component="button"
          underline="none"
          onClick={e => {
            this.handleClickListItem(e)
          }}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <MuiAvatar
            avatarSrc={LANGUAGE_DETAILS.filter(e => e.lang === language)[0].flag}
            label={<ReduxLanguage t={`languages.${language}`} />}
            width={20}
            height={20}
            fontSize={18}
          />
        </Link>
        <Menu
          id="lock-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => this.handleClose()}
          MenuListProps={{
            'aria-labelledby': 'lock-button',
            role: 'listbox',
          }}
        >
          {LANGUAGE_DETAILS.map((el, index) => (
            <MenuItem
              key={el.key}
              onClick={(event) => this.handleMenuItemClick(event, index)}
            >
              <MuiAvatar
                avatarSrc={el.flag}
                label={<ReduxLanguage t={`languages.${el.lang}`} />}
                width={20}
                height={20}
                fontSize={18}
              />
            </MenuItem>
          ))}
        </Menu>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    language: state.settings.language
  }
}

export default connect(mapStateToProps)(LanguageSelector);

