import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import ReduxLanguage from '../Language/ReduxLanguage';

const EmptyInstallationList: React.FunctionComponent = props => {
  return (
    <Box>
      <Typography variant="h4" color="text.secondary" fontWeight="bold" align="center">
        <ReduxLanguage t="installations.emptyInstallationTitle" />
      </Typography>
      <Typography variant="h6" color="text.secondary" align="center" fontWeight="light">
        <ReduxLanguage t="installations.emptyInstallationDescription" />
      </Typography>
    </Box>
  );
}

export default EmptyInstallationList;
