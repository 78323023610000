import UsersActionTypesEnum from './model/UsersActionTypesEnum';
import { createAction, ActionsUnion, IThunkAsyncAction } from '../utils';
import { IState } from '..';
import { User } from './model/Users';
import storageService from '../../services/storage/impl/storageService';
import StorageKeys from '../../services/storage/model/StorageKeys';
import authService from '../../services/auth/impl/authService';

export const UsersActions = {
  setCurrentLoggedUser: createAction<typeof UsersActionTypesEnum.SET_CURRENT_LOGGED_USER, User>(UsersActionTypesEnum.SET_CURRENT_LOGGED_USER),
};

export function loginUser(username: string, password: string): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const user = await authService.loginUser(username.trim(), password.trim());
      dispatch(UsersActions.setCurrentLoggedUser({ username: username, isAuthenticated: true }));
      await storageService.setItem(StorageKeys.AUTH_TOKEN, user.access_token);
      await storageService.setItem(StorageKeys.IS_AUTHENTICATED, true);
      await storageService.setItem(StorageKeys.USER, username);
    } catch (e) {
      throw e;
    }
  };
}

export function getLoginInfoFromStorage(): IThunkAsyncAction<User, IState> {
  return async (dispatch, getState) => {
    const storedUser = await storageService.getItem(StorageKeys.USER);
    const isAuthenticated = await storageService.getItem(StorageKeys.IS_AUTHENTICATED);
    const user: User = { username: storedUser, isAuthenticated };
    dispatch(UsersActions.setCurrentLoggedUser(user));
    return user;
  };
}

export function logoutUser(): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    dispatch(UsersActions.setCurrentLoggedUser({ username: "", isAuthenticated: false }));
    await storageService.setItem(StorageKeys.IS_AUTHENTICATED, false);
    await storageService.removeItem(StorageKeys.USER);
  };
}


export type UsersActionsType = ActionsUnion<typeof UsersActions>;
