import TemplateActionTypesEnum from './model/TemplateActionTypesEnum';
import { createAction, ActionsUnion, IThunkAsyncAction } from '../utils';
import { IState } from '..';
import { Template } from './model/Template';
import { templateApi } from '../../repository/vitruvian/vitruvianApi';

export const TemplateActions = {
  setTemplates: createAction<typeof TemplateActionTypesEnum.SET_TEMPLATES, Template[]>(TemplateActionTypesEnum.SET_TEMPLATES),
  setSelectedTemplate: createAction<typeof TemplateActionTypesEnum.SET_SELECTED_TEMPLATE, Template>(TemplateActionTypesEnum.SET_SELECTED_TEMPLATE),
};

export function fetchTemplates(): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const templates = await templateApi.getTemplatesUsingGET();
      dispatch(TemplateActions.setTemplates(templates.data)); 
    } catch (e) {
      throw e;
    }
  };
}

export type TemplateActionsType = ActionsUnion<typeof TemplateActions>;
