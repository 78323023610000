import _ from 'lodash';
import SeamlessImmutable, { ImmutableObject } from 'seamless-immutable';
import { ModalActionsType } from './modal.actions';
import ModalActionTypesEnum from './model/ModalActionTypesEnum';
import ModalProps from './model/ModalProps';

export type IModalReduxState = {
  data: ModalProps<Object>[],
};

const initialState = SeamlessImmutable<IModalReduxState>({
  data: [],
});

export default function reducer(state: ImmutableObject<IModalReduxState> = initialState, action: ModalActionsType) {
  switch (action.type) {
    case ModalActionTypesEnum.SHOW_MODAL:
      return state.set('data', [...state.data.asMutable(), { id: action.payload.id, data: action.payload.data }]);
    case ModalActionTypesEnum.HIDE_MODAL:
      return state.set('data', _.filter(state.data, d => d.id !== action.payload));
    default:
      return state;
  }
}
