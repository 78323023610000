import { AppBar, Icon, Toolbar, Typography } from "@mui/material";
import React from "react";
import { APPBAR_BACKGROUND_COLOR } from "../../config/style";
import UserAvatar from "../User/UserAvatar";
import { isAuthRoute } from "../../utils/router";
import { IState } from "../../redux";
import { User } from "../../redux/users/model/Users";
import { connect } from "react-redux";
import BottegaLogo from '../../assets/Images/bottega52logo.svg';

interface VitruvianAppBarProps {
  routePath: string;
  user: User;
}

class VitruvianAppBar extends React.Component<VitruvianAppBarProps> {
  render() {
    const { routePath, user } = this.props;

    const isAuthPath = isAuthRoute(routePath);

    return (
      <>
        <AppBar position="fixed" style={{ background: APPBAR_BACKGROUND_COLOR, boxShadow: 'none' }} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <Toolbar variant="dense">
            {
              isAuthPath ?
                /* <img src={Brand} alt="brand"/> */
                null :
                <div style={{ flex: 1 }}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: 200 }}>
                    <Typography
                      sx={{ flexGrow: 1, color: "black" }}
                      fontWeight="bold"
                      fontSize={22}
                      style={{ marginTop: 5 }}
                    >
                      VITRUVIAN
                    </Typography>
                    <Icon style={{ textAlign: 'center', width: '100%' }}>
                      <img alt="logo" style={{ height: 18 }} src={BottegaLogo} />
                    </Icon>
                  </div>
                </div>
            }
            {!isAuthPath ?
              <UserAvatar
                username={user.username}
              /> :
              null
            }
          </Toolbar>
        </AppBar>
      </>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    user: state.users.currentUser
  }
}
export default connect(mapStateToProps)(VitruvianAppBar);
