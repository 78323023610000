import SeamlessImmutable from 'seamless-immutable';
import FileManagerActionTypesEnum from './model/FileManagerActionTypesEnum';
import { FileManagerActionsType } from './fileManager.actions';
import { IListObject } from './model/FileManager';


export interface IFileManagerInterface {
  buckets: string[];
  selectedBucket: string;
  bucketListObject: IListObject[];
  selectedListObject?: IListObject;
}

const initialState = SeamlessImmutable<IFileManagerInterface>({
  buckets: [],
  selectedBucket: '',
  bucketListObject: [],
});

export default function fileManagerReducers(state: SeamlessImmutable.ImmutableObject<IFileManagerInterface> = initialState, action: FileManagerActionsType) {
  switch (action.type) {
    case FileManagerActionTypesEnum.SET_BUCKETS:
      return state.set('buckets', action.payload);
    case FileManagerActionTypesEnum.SET_SELECTED_BUCKET:
      return state.set('selectedBucket', action.payload);
    case FileManagerActionTypesEnum.SET_LIST_OBJECTS:
      return state.set('bucketListObject', action.payload);
    case FileManagerActionTypesEnum.SET_SELECTED_LIST_OBJECTS:
      return state.set('selectedListObject', action.payload);
    default:
      return state;
  }
}
