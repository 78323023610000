import { IconButton, Menu, MenuItem } from '@mui/material';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ReduxLanguage from '../Language/ReduxLanguage';
interface InstallationMenuProps {
  onActivate: () => void;
  onDeactivate: () => void;
  onDelete: () => void;
  onClone: () => void;
  onExport: () => void;
}

interface MenuElement {
  key: string;
  label: string;
  onClick: () => void;
}

const InstallationMenu: React.FunctionComponent<InstallationMenuProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const menuElements: MenuElement[] = [
    {
      key: 'activate',
      label: 'installations.menu.activate',
      onClick: () => props.onActivate(),
    },
    {
      key: 'deactivate',
      label: 'installations.menu.deactivate',
      onClick: () => props.onDeactivate(),
    },
    {
      key: 'delete',
      label: 'installations.menu.delete',
      onClick: () => props.onDelete(),
    },
    {
      key: 'clone',
      label: 'installations.menu.clone',
      onClick: () => props.onClone(),
    },
    {
      key: 'exportData',
      label: 'installations.menu.exportData',
      onClick: () => props.onExport(),
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleItemClick = (option: MenuElement) => {
    option.onClick();
    handleClose();
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls="long-menu"
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        {menuElements.map((option) => (
          <MenuItem key={option.key} onClick={() => handleItemClick(option)}>
            <ReduxLanguage t={option.label} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default InstallationMenu;