import { combineReducers } from 'redux';
import modals, { IModalReduxState } from './modal/modal.reducers';
import settings, { ISettingsInterface } from './settings/settings.reducers';
import users, { IUsersInterface } from './users/users.reducers';
import installation, { IInstallationInterface } from './installation/installation.reducers';
import template, { ITemplateInterface } from './template/template.reducers';


import { RouterState, connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import fileManager, { IFileManagerInterface } from './fileManager/fileManager.reducers';

export const history = createBrowserHistory();

const rootReducers = combineReducers({
  modals,
  router: connectRouter(history),
  settings,
  users,
  installation,
  template,
  fileManager,
});

export interface IState {
  modals: IModalReduxState,
  router: RouterState,
  settings: ISettingsInterface,
  users: IUsersInterface,
  installation: IInstallationInterface,
  template: ITemplateInterface,
  fileManager: IFileManagerInterface,
}

export default rootReducers;
