import React from 'react';
import _ from 'lodash';
import LocationCard from './LocationCard';
import { Typography } from '@mui/material';
import { IContinent } from '../Onboarding/EnviromentConfiguration';
import { LocationOutDTO } from '../../repository/vitruvian/client';

interface LocationListProps {
  locations: IContinent[] | LocationOutDTO[];
  onSelectLocation: (l: IContinent | LocationOutDTO) => void;

  selectedLocation: string | number;

  title: string | JSX.Element;

  style?: React.CSSProperties | undefined;
}

interface LocationListState {

}

class LocationList extends React.Component<LocationListProps, LocationListState> {

  selectLocation(l: IContinent | LocationOutDTO) {
    this.props.onSelectLocation(l);
  }
  render() {
    const { locations, title } = this.props;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <Typography variant="h5" style={{ marginBottom: 20 }} color="text.secondary">
          {title}
        </Typography>
        <div style={this.props.style}>
          {locations.length ? _.map(locations, (location, index) => (
            <div style={{ width: '100%', paddingBottom: 10 }} key={index}>
              <LocationCard
                location={location as IContinent | LocationOutDTO}
                onClick={(l) => this.selectLocation(l)}
                selectedLocation={this.props.selectedLocation}
              />
            </div>
          )) :
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90%' }}>
              <Typography variant="h5" color="text.secondary" fontWeight="bold" align="center">No Location Found</Typography>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default LocationList;