import InstallationActionTypesEnum from './model/InstallationActionTypesEnum';
import { createAction, ActionsUnion, IThunkAsyncAction } from '../utils';
import { IState } from '..';
import { Template } from '../template/model/Template';
import { DEFAULT_PAGE_SIZE, Installation, Installations } from './model/Installations';
import { instanceApi } from '../../repository/vitruvian/vitruvianApi';
import { InstanceDetailsOutDTO, InstanceInDTO, InstanceOutDTO, LocationOutDTO } from '../../repository/vitruvian/client';
import _ from 'lodash';

interface InstallationPayload {
  index: number;
  installation: InstanceOutDTO;
}

export const InstallationActions = {
  setInstallations: createAction<typeof InstallationActionTypesEnum.SET_INSTALLATIONS, Installations>(InstallationActionTypesEnum.SET_INSTALLATIONS),
  saveInstallationByIndex: createAction<typeof InstallationActionTypesEnum.SAVE_INSTALLATION_BY_ID, InstallationPayload>(InstallationActionTypesEnum.SAVE_INSTALLATION_BY_ID),
  setOnboardingLocation: createAction<typeof InstallationActionTypesEnum.SET_ONBOARDING_DATA_LOCATION, LocationOutDTO | undefined>(InstallationActionTypesEnum.SET_ONBOARDING_DATA_LOCATION),
  setOnboardingContinent: createAction<typeof InstallationActionTypesEnum.SET_ONBOARDING_DATA_CONTINENT, string>(InstallationActionTypesEnum.SET_ONBOARDING_DATA_CONTINENT),
  setOnboardingTemplate: createAction<typeof InstallationActionTypesEnum.SET_ONBOARDING_DATA_TEMPLATE, Template>(InstallationActionTypesEnum.SET_ONBOARDING_DATA_TEMPLATE),
  setOnboardingInstallationData: createAction<typeof InstallationActionTypesEnum.SET_ONBOARDING_DATA_INSTALLATION_DATA, { key: string, value: any }>(InstallationActionTypesEnum.SET_ONBOARDING_DATA_INSTALLATION_DATA),
  resetOnboardingData: createAction<typeof InstallationActionTypesEnum.RESET_ONBOARDING_DATA, void>(InstallationActionTypesEnum.RESET_ONBOARDING_DATA),
  setSelectedInstallation: createAction<typeof InstallationActionTypesEnum.SET_SELECTED_INSTALLATION, Installation | undefined>(InstallationActionTypesEnum.SET_SELECTED_INSTALLATION),
};

export function fetchInstallations(page: number = 0, pageSize: number = DEFAULT_PAGE_SIZE, append: boolean = false): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const installationsState = getState().installation.installations;
      // TODO: add dynamic
      const installations = await instanceApi.getInstancesUsingGET(page, pageSize, "DESC", "id");
      if (append) {
        const content = installationsState.content || [];
        const newInstallationsContent = installations.data.content || [];
        const i: Installations = {
          ...installations.data,
          content: [...content, ...newInstallationsContent],
        }
        dispatch(InstallationActions.setInstallations(i));
      } else {
        dispatch(InstallationActions.setInstallations(installations.data));
      }
    } catch (e) {
      throw e;
    }
  };
}

export function createInstallations(): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const onboardingData = getState().installation.onboardingData;
      const instanceInDTO: InstanceInDTO = {
        customer: 'toshiba',
        demoDataEnabled: onboardingData.installationData?.isDemo || false,
        description: onboardingData.installationData?.description || "",
        locationId: onboardingData.selectedLocation!.id,
        name: onboardingData.installationData!.name,
        templateId: onboardingData.selectedTemplate!.id,
        visualStoreVersionId: onboardingData.installationData?.version.id,
      }
      const installation = await instanceApi.createInstanceUsingPOST(instanceInDTO);
      dispatch(InstallationActions.setSelectedInstallation(installation.data));
    } catch (e) {
      throw e;
    }
  };
}

export function updateInstallationState(installation: InstanceDetailsOutDTO): IThunkAsyncAction<void, IState> {
  return async (dispatch, getState) => {
    try {
      const installationsList = getState().installation.installations.content;
      const index = _.findIndex(installationsList, i => i.id === installation.id);
      if (index !== -1) {
        dispatch(InstallationActions.saveInstallationByIndex({ index, installation }));
      }
    } catch (e) {
      throw e;
    }
  };
}
export type InstallationActionsType = ActionsUnion<typeof InstallationActions>;
