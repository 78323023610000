import { Typography, Link, Button, Divider } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Installation } from '../../redux/installation/model/Installations';
import { InstanceDetailsOutDTO, LocationOutDTO } from '../../repository/vitruvian/client';
import { instanceApi } from '../../repository/vitruvian/vitruvianApi';
import { formatLocationName, formatTemplateName } from '../../utils/installations';
import ReduxLanguage from '../Language/ReduxLanguage';
import ExposedServicesList from './ExposedServicesList';
import _ from 'lodash';

interface InstallationDetailsDescriptionProps {
  onRequestTicket: (installation: Installation) => void;
  installation: Installation;
  installationDetails?: InstanceDetailsOutDTO;
}

const InstallationDetailsDescription: React.FunctionComponent<InstallationDetailsDescriptionProps> = props => {
  const [credentialUrl, setCredentialUrl] = useState("");
  const { installation, installationDetails } = props;
  const [metricsLink, setMetricsLink] = useState("");


  useEffect(() => {
    const init = async () => {
      await fetchDownloadFileUrl();
      const link = _.filter(props.installation.exposedServices, e => {
        return e.name === 'grafana';
      });
      if (link && link.length && link[0].hostname) {
        setMetricsLink(`http://${link[0].hostname}`);
      }
    }

    init();
    // eslint-disable-next-line
  }, []);

  const fetchDownloadFileUrl = async () => {
    if (props.installation.id) {
      const resource = (await instanceApi.getInstanceSecretsUsingGET(props.installation!.id)).data;
      var blob = new Blob([resource as any], { type: 'text/csv' });
      var url = window.URL.createObjectURL(blob);
      setCredentialUrl(url);
    }
  }

  let descriptionMap = { "items": 0, "promotions": 0, "stores": 0 };
  if (installation?.templateOutDTO?.description) {
    const parsedDesc = JSON.parse(installation.templateOutDTO.description);
    if (!_.isEmpty(parsedDesc)) {
      descriptionMap = parsedDesc;
    }
  }

  return (
    <>
      <Typography variant="h5" fontWeight="bold">
        <ReduxLanguage t="installations.details" />:
      </Typography>

      <div style={{ marginTop: 20 }}>
        <Typography variant="h6" color="text.secondary" style={{ marginTop: 10 }}>
          <ReduxLanguage t="installations.enviroment" />
        </Typography>
        <div style={{ display: 'flex' }}>
          <Typography fontWeight="light">
            <ReduxLanguage t="installations.visualStoreVersion" />:&nbsp;
          </Typography>
          <Typography>
            {installation.visualStoreVersionOutDTO?.version}
          </Typography>
        </div>
        <div style={{ display: 'flex' }}>
          <Typography fontWeight="light">
            <ReduxLanguage t="installations.location" />:&nbsp;
          </Typography>
          <Typography>
            {formatLocationName(installation.locationOutDTO as LocationOutDTO, installation.templateOutDTO)}
          </Typography>
        </div>
      </div>

      <div style={{ marginTop: 20 }}>
        <Typography variant="h6" color="text.secondary" style={{ marginTop: 10 }}>
          KPI
        </Typography>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          {
            _.map(descriptionMap, (e, key) => (
              <div key={key} style={{ display: 'flex' }}>
                <Typography fontWeight="light">
                  {key}:&nbsp;
                </Typography>
                <Typography>
                  {e}
                </Typography>
              </div>
            ))
          }
        </div>
      </div>
      <div style={{ marginTop: 20 }}>
        {installation.exposedServices && installation.exposedServices.length ?
          <ExposedServicesList
            exposedServices={installation.exposedServices || []}
          /> :
          null
        }
      </div>
      <div style={{ marginTop: 20 }}>
        <Typography variant="h6" color="text.secondary">
          <ReduxLanguage t="installations.configuration" />
        </Typography>
        <Link download="credentials.csv" href={credentialUrl}>
          <ReduxLanguage t="installations.downloadConfFiles" />
        </Link>
      </div>
      <div style={{ marginTop: 20 }}>
        {metricsLink ?
          <Typography variant="h6" color="text.secondary">
            <ReduxLanguage t="installations.monitoring" />
          </Typography> :
          null
        }
        {metricsLink ?
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Link target="_blank" href={metricsLink}>
              <ReduxLanguage t="installations.metrics" />
            </Link>
            <Link target="_blank" href={metricsLink}>
              <ReduxLanguage t="installations.logs" />
            </Link>
          </div> : null}
      </div>

      {/* <div style={{ marginTop: 20 }}>
        <Typography variant="h6" color="text.secondary">
          <ReduxLanguage t="installations.maintenance" />
        </Typography>
        <Link target="_blank" href="">
          <ReduxLanguage t="installations.accessCluster" />
        </Link>
      </div> */}

      <Divider style={{ padding: 20 }} />

      <div style={{ marginTop: 20 }}>
        <Typography variant="h6" color="text.secondary">
          <ReduxLanguage t="installations.billing" />
        </Typography>
        <Typography color="text.secondary">
          {formatTemplateName(installation.templateOutDTO?.size || "", installation.templateOutDTO?.environment || "", "")} - {installation.templateOutDTO?.sku}
        </Typography>
        <Typography color="text.secondary">
          <ReduxLanguage t="installations.actualCost" languageProps={{ credits: installationDetails?.actualCost || 0 }} />
        </Typography>
      </div>

      <div style={{ marginTop: 20 }}>
        <Typography variant="h6" color="text.secondary">
          <ReduxLanguage t="installations.support" />
        </Typography>
        <Button
          variant="contained"
          style={{ marginTop: 20 }}
          onClick={() => props.onRequestTicket(installation)}
        >
          <ReduxLanguage t="installations.requestTicket" />
        </Button>
      </div>
    </>
  );
}

export default InstallationDetailsDescription;