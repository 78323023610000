import { Avatar, Typography } from '@mui/material';
import React from 'react';

interface MuiAvatarProps {
  avatarSrc: string;
  label: string | JSX.Element;
  width?: number;
  height?: number;
  fontSize?: number;
}

const MuiAvatar: React.FunctionComponent<MuiAvatarProps> = props => {
  return (
    <>
      <Avatar
        alt="Avatar"
        src={props.avatarSrc}
        style={{ marginRight: 5, width: props.width || 16, height: props.height || 16 }}
      />
      <Typography fontSize={props.fontSize || 16}>
        {props.label}
      </Typography>
    </>
  );
}

export default MuiAvatar;