import { List, ListItem, Typography, Link } from '@mui/material';
import React from 'react';
import ReduxLanguage from '../Language/ReduxLanguage';
import _ from 'lodash';
import { InstanceExposedServiceDTO } from '../../repository/vitruvian/client';

interface ExposedServicesListItemProps {
  label: string;
  link: string;
}

const ExposedServicesListItem: React.FunctionComponent<ExposedServicesListItemProps> = props => (
  <ListItem sx={{ padding: 0, flexWrap: 'wrap' }}>
    <Typography fontWeight="light">
      {props.label}&nbsp;
    </Typography>
    <Link target="_blank" href={`http://${props.link}`}>
      {props.link}
    </Link>
  </ListItem>
);

interface ExposedServicesListProps {
  exposedServices: InstanceExposedServiceDTO[];
}

interface ExposedServicesListState { }

class ExposedServicesList extends React.Component<ExposedServicesListProps, ExposedServicesListState> {
  render() {
    const { exposedServices } = this.props;

    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="h6" color="text.secondary">
          <ReduxLanguage t="installations.exposedServices" />
        </Typography>
        <List dense disablePadding sx={{ padding: 0 }} style={{ marginTop: 10 }}>
          {
            _.map(exposedServices, (e, index) => (
              e.hostname ? <ExposedServicesListItem
                key={index}
                label={`${e.name}: `}
                link={e.hostname}
              /> : null
            ))
          }
        </List>
      </div >
    );
  }
}
export default ExposedServicesList;
