import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// tslint:disable-next-line: interface-name
export interface ReplayRequestParams {
  initialRequest: AxiosRequestConfig;
  resolve: (r: AxiosResponse<any>) => any;
  reject: (r: any) => any;
}
export function createAxios(config?: any): AxiosInstance {
  return axios.create({
    timeout: 30000,
    ...config,
  });
}
export function ejectRequestInterceptor(axiosInstance: AxiosInstance, key: number): void {
  axiosInstance.interceptors.request.eject(key);
}
export function setRequestInterceptor(axiosInstance: AxiosInstance, interceptor: any): number {
  return axiosInstance.interceptors.request.use(interceptor);
}
export function setResponseInterceptor(
  axiosInstance: AxiosInstance,
  onFulfilled?: (value: AxiosResponse) => AxiosResponse,
  onRejected?: (error: any) => any
): number {
  return axiosInstance.interceptors.response.use(onFulfilled, onRejected);
}
export function ejectResponseInterceptor(axiosInstance: AxiosInstance, key: number): void {
  axiosInstance.interceptors.response.eject(key);
}

export function replayRequest(params: ReplayRequestParams) {
  axios(params.initialRequest).then((response: AxiosResponse) => {
    params.resolve(response);
  }).catch((reason: any) => {
    params.reject(reason);
  });
}

export function setBaseUrl(axiosInstance: AxiosInstance, baseURL: string) {
  axiosInstance.defaults.baseURL = baseURL;
}

export function getBaseUrl(axiosInstance: AxiosInstance) {
  return axiosInstance.defaults.baseURL;
}

export function addAxiosLogger(axiosInstance: AxiosInstance) {
  axiosInstance.interceptors.request.use((request) => {
    return request;
  });
  axiosInstance.interceptors.response.use((response) => {
    return response;
  });
}
