import { Card, Link, Typography, Box } from '@mui/material';
import React from 'react';
import { Template } from '../../redux/template/model/Template';
import { formatTemplateName } from '../../utils/installations';
import _ from 'lodash';
import ReduxLanguage from '../Language/ReduxLanguage';

interface TemplateCardProps {
  onSelectTemplate: (t: Template) => void;
  template: Template;
  selectedTemplateId?: number;
}

const TemplateCard: React.FunctionComponent<TemplateCardProps> = props => {
  const { selectedTemplateId, template } = props;

  let descriptionMap = { "items": 0, "promotions": 0, "stores": 0, "transactions": 0 };
  if (template.description) {
    const parsedDesc = JSON.parse(template.description);
    if (!_.isEmpty(parsedDesc)) {
      descriptionMap = parsedDesc; // default value
    }
  }

  return (
    <Link component="button" underline='none' onClick={() => props.onSelectTemplate(props.template)}>
      <Card
        sx={{
          width: 250,
          border: 4,
          borderColor: selectedTemplateId === template.id ? 'red' : 'transparent',
          minHeight: 250,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'space-between', height: '100%', marginTop: 1, marginBottom: 1 }}>
          <Typography variant="h5" fontWeight="bold" color="text.secondary">
            {formatTemplateName(template.size || "", template.environment || "", template.providerOutDTO?.name || "")}
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 5, flexWrap: 'wrap' }}>
            {
              _.map(descriptionMap, (e, key) => (
                <div key={key} style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', padding: 10, flexWrap: 'wrap', alignSelf: 'center' }}>
                  <Typography fontWeight="bold" align="center">
                    {key}
                  </Typography>
                  <Typography align="center">
                    {e}
                  </Typography>
                </div>
              ))
            }
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 5 }}>
            <Typography variant="h6" color="text.secondary" fontWeight="bold">
              <ReduxLanguage t="template.costHour" />:&nbsp;
            </Typography>
            <Typography variant="h6" color="text.secondary" fontWeight="bold">
              <ReduxLanguage t="template.credits" languageProps={{ credits: template.costPerHour }} />
            </Typography>
          </div>
        </Box>
      </Card>
    </Link>

  );
}

export default TemplateCard;