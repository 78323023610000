import { DEFAULT_BASE_API_URL } from "../../config";
import { LoginControllerApi } from "./implementation/LoginControllerApi";
import { createAxios, setBaseUrl, setRequestInterceptor, setResponseInterceptor } from "../utils/axios";
import { InstanceControllerApi, LocationControllerApi, StorageControllerApi, TemplateControllerApi, VisualStoreVersionControllerApi } from "./client";
import { AxiosRequestConfig, AxiosResponse } from 'axios';

export const vitruvianAxiosInstance = createAxios();

setBaseUrl(vitruvianAxiosInstance, DEFAULT_BASE_API_URL);

export function setVitruvianResponseInterceptor(onRejected?: (error: any) => any) {
  setResponseInterceptor(vitruvianAxiosInstance, (response: AxiosResponse) => response, onRejected);
}

export function setVitruvianRequestInterceptor(interceptor?: (config: AxiosRequestConfig) => any) {
  setRequestInterceptor(vitruvianAxiosInstance, interceptor);
}

export let loginApi = new LoginControllerApi(undefined, "", vitruvianAxiosInstance);
export let instanceApi = new InstanceControllerApi(undefined, "", vitruvianAxiosInstance);
export let templateApi = new TemplateControllerApi(undefined, "", vitruvianAxiosInstance);
export let locationApi = new LocationControllerApi(undefined, "", vitruvianAxiosInstance);
export let visualStoreVersionApi = new VisualStoreVersionControllerApi(undefined, "", vitruvianAxiosInstance);
export let storageApi = new StorageControllerApi(undefined, "", vitruvianAxiosInstance);

export function createApiInstances(baseUrl: string) {
  setBaseUrl(vitruvianAxiosInstance, baseUrl);
}
