import { Button } from '@mui/material';
import React from 'react';
import ReduxLanguage from '../Language/ReduxLanguage';
import LoadingButton from '@mui/lab/LoadingButton';

interface OnboardingActionsProps {
  onGoBack?: () => void;
  onGoNext?: () => void;
  onFinish?: () => void;
  onDowbload?: () => void;
  disabledNext: boolean;
  backLabel?: string;
  nextLabel?: string;
  isLoadingAction: boolean;
}

const OnboardingActions: React.FunctionComponent<OnboardingActionsProps> = props => {
  const goBack = () => {
    if (props.onGoBack) {
      props.onGoBack();
    }
  }

  const goNext = () => {
    if (props.onGoNext) {
      props.onGoNext();
    }
  }

  const download = () => {
    if (props.onDowbload) {
      props.onDowbload();
    }
  }
  return (
    <React.Fragment>
      {props.onGoBack != null ?
        <Button
          onClick={() => goBack()}
          style={{ width: 150 }}
          disabled={props.isLoadingAction}
        >
          <ReduxLanguage t="back" />
        </Button> :
        null
      }
      {props.onDowbload ?
        <Button
          variant="contained"
          style={{ marginLeft: 10, width: 150 }}
          onClick={() => download()}
          disabled={props.disabledNext}
        >
          <ReduxLanguage t="download" />
        </Button> :
        null
      }
      {props.onGoNext ?
        <LoadingButton
          variant="contained"
          style={{ marginLeft: 10, width: 150 }}
          onClick={() => goNext()}
          disabled={props.disabledNext}
          loading={props.isLoadingAction}

        >
          <ReduxLanguage t={props.nextLabel ? props.nextLabel : "next"} />
        </LoadingButton> :
        null
      }
    </React.Fragment >
  );
}

export default OnboardingActions;
