import { DispatchProp } from "react-redux";

export interface ModalDataProps<T> {
  modalType: string;
  modalProps?: T;
}

export interface ModalProps<T> {
  id: string;
  data: ModalDataProps<T>;
}



export enum ModalTypes {
  ALERT_DIALOG = 'ALERT_DIALOG',
}

export interface IModalViewProps<T> extends DispatchProp {
  hideModal: () => void;
  modalProps?: T;
}