import { AxiosResponse } from "axios";
import { createAxios, setBaseUrl } from "../../utils/axios";
import { BackendInfo } from "../model/Authorizations";

export default class AuthenticationControllerApi {

  private authAxiosInstance;

  constructor() {
    this.authAxiosInstance = createAxios();
    setBaseUrl(this.authAxiosInstance, window.location.origin);
  }

  getBackendInfo(): Promise<AxiosResponse<BackendInfo>> {
    return this.authAxiosInstance.get('/api/v1/backendInfo');
  }
}
