import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';

type FunctionType = (...args: any[]) => any;
type ActionCreatorsMapObject = { [actionCreator: string]: FunctionType }

export interface Action<T extends string> {
  type: T
}

export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P
}

type ActionFn<T extends string> = () => Action<T>;
type ActionWithPayloadFn<T extends string, P> = (payload: P) => ActionWithPayload<T, P>;

export function createAction<T extends string>(type: T): ActionFn<T>;
export function createAction<T extends string, P>(type: T): ActionWithPayloadFn<T, P>;
export function createAction(type: string) {
  return (payload?: any) => payload != null ? { type, payload }: { type };
};


export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;

export declare type ThunkResult<ReturnType, IState> = ThunkAction<ReturnType, IState, undefined, AnyAction>;
export declare type IThunkAction<ReturnType, IState> = ThunkResult<ReturnType, IState>;
export declare type IThunkAsyncAction<ReturnType, IState> = ThunkResult<Promise<ReturnType>, IState>;

export interface ActionPayload {
  field: string;
  value: any;
}
