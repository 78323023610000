import { InstanceOutDTO, PageOfInstanceOutDTO } from "../../../repository/vitruvian/client";

export enum InstallationStatusEnum {
  PENDING = 'PENDING',
  ACTIVE = 'ACTIVE',
  ERROR = 'ERROR'
}
export interface Installations extends PageOfInstanceOutDTO { }
export interface Installation extends InstanceOutDTO { }
export type Continent = "NORTH_AMERICA" | "SOUTH_AMERICA" | "MIDDLE_EAST" | "ASIA_PACIFIC" | "AFRICA" | "EUROPE" | "AUSTRALIA";

export const DEFAULT_PAGE_SIZE = 5;
