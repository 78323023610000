import { Box, Button, IconButton, Snackbar } from '@mui/material';
import React from 'react';
import InstallationsList from '../../components/Installations/InstallationsList';
import { MAIN_BACKGROUND_COLOR } from '../../config/style';
import AddIcon from '@mui/icons-material/Add';
import InstallationOnboarding from '../../components/Installations/InstallationOnboarding';
import ComponentAppBarWrapper from '../../components/Appbar/ComponentAppBarWrapper';
import * as installationActions from '../../redux/installation/installation.actions';
import { IState } from '../../redux';
import IDispatchProps from '../../redux/IDispatchProps';
import { connect } from 'react-redux';
import { IInstallationInterface } from '../../redux/installation/installation.reducers';
import ReduxLanguage from '../../components/Language/ReduxLanguage';
import EmptyInstallationList from '../../components/Installations/EmptyInstallationList';
import { DEFAULT_PAGE_SIZE, Installation } from '../../redux/installation/model/Installations';
import CloseIcon from '@mui/icons-material/Close';
import RefreshIcon from '@mui/icons-material/Refresh';


interface InstallationsPageProps extends IDispatchProps {
  installationState: IInstallationInterface;
}

interface InstallationPageState {
  isInstallationOnboarding: boolean;
  isSneakbarVisible: boolean;
}

class InstallationsPage extends React.Component<InstallationsPageProps, InstallationPageState> {
  constructor(props: InstallationsPageProps) {
    super(props);
    this.state = {
      isInstallationOnboarding: false,
      isSneakbarVisible: false,
    }
  }

  componentDidMount() {
    this.fetchInstallations();
  }

  componentWillUnmount() {
    const { dispatch } = this.props;

    dispatch(installationActions.InstallationActions.resetOnboardingData());
  }

  refreshInstallation() {
    const { dispatch } = this.props;

    dispatch<any>(installationActions.InstallationActions.setInstallations({}));
    this.fetchInstallations();
  }

  fetchInstallations(page: number = 0, pageSize: number = DEFAULT_PAGE_SIZE, append: boolean = false) {
    const { dispatch } = this.props;
    try {
      dispatch<any>(installationActions.fetchInstallations(page, pageSize, append));
    } catch (error) {
      // TODO: handle error
    }
  }

  onCreateInstallationClick() {
    this.setState({ isInstallationOnboarding: true });
  }

  cancelOnboarding() {
    this.setState({ isInstallationOnboarding: false });
  }

  finishOnboarding() {
    this.setState({ isInstallationOnboarding: false, isSneakbarVisible: true });
    this.fetchInstallations();
  }

  loadMoreInstallations(nextPage: number) {
    this.fetchInstallations(nextPage, DEFAULT_PAGE_SIZE, true);
  }

  selectInstallation(installation?: Installation) {
    const { dispatch } = this.props;

    dispatch(installationActions.InstallationActions.setSelectedInstallation(installation));
  }

  render() {
    const { isInstallationOnboarding } = this.state;
    const { installationState: { installations, selectedInstallation } } = this.props;

    return (
      <ComponentAppBarWrapper>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: MAIN_BACKGROUND_COLOR,
          }}
        >
          {!isInstallationOnboarding ?
            <React.Fragment>
              <Box
                sx={{ display: 'flex', justifyContent: 'flex-end' }}
                style={{ paddingRight: 40, paddingTop: 20, paddingBottom: 10, paddingLeft: 40 }}
              >
                <Button
                  variant="outlined"
                  startIcon={<RefreshIcon />}
                  onClick={() => this.refreshInstallation()}
                >
                  <ReduxLanguage t="installations.refreshInstallation" />
                </Button>
                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() => this.onCreateInstallationClick()}
                >
                  <ReduxLanguage t="installations.createInstallation" />
                </Button>
              </Box>
              <div style={{ height: 'calc(100vh - 130px)', paddingLeft: 30, paddingRight: 30 }}>
                {installations?.content?.length ?
                  <InstallationsList
                    installations={installations}
                    onLoadMore={nextPage => this.loadMoreInstallations(nextPage)}
                    selectedInstallation={selectedInstallation}
                    onSelectInstallation={i => this.selectInstallation(i)}
                  /> :
                  <div style={{ display: 'flex', height: '80%', alignItems: 'center', justifyContent: 'center' }}>
                    <EmptyInstallationList />
                  </div>
                }
              </div>
            </React.Fragment> :
            <InstallationOnboarding
              onCancelOnboarding={() => this.cancelOnboarding()}
              onFinishOnboarding={() => this.finishOnboarding()}
            />
          }
        </Box>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.isSneakbarVisible}
          autoHideDuration={5000}
          onClose={() => this.setState({ isSneakbarVisible: false })}
          message={<ReduxLanguage t="installations.installationCreated" />}
          action={
            <React.Fragment>
              <IconButton
                aria-label="close"
                color="inherit"
                sx={{ p: 0.5 }}
                onClick={() => this.setState({ isSneakbarVisible: false })}
              >
                <CloseIcon />
              </IconButton>
            </React.Fragment>
          }
        />
      </ComponentAppBarWrapper>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    installationState: state.installation
  }
}

export default connect(mapStateToProps)(InstallationsPage);
