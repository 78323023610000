import React from 'react';
import { Box } from '@mui/system';
import { CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import ReduxLanguage from '../../components/Language/ReduxLanguage';
import FileManagerComponent from '../../components/FileManager/FileManagerComponent';
import IDispatchProps from '../../redux/IDispatchProps';
import { IState } from '../../redux';
import { connect } from 'react-redux';
import { IFileManagerInterface } from '../../redux/fileManager/fileManager.reducers';
import * as FileManagerActions from '../../redux/fileManager/fileManager.actions';
import _ from 'lodash';
import { IListObject } from '../../redux/fileManager/model/FileManager';
import { PresignedUrlDTO } from '../../repository/vitruvian/client';
import { ModalTypes } from '../../redux/modal/model/ModalTypes';
import { showModal } from '../../redux/modal/modal.actions';
import RefreshIcon from '@mui/icons-material/Refresh';
import ComponentAppBarWrapper from '../../components/Appbar/ComponentAppBarWrapper';

interface FileManagerPageProps extends IDispatchProps {
  fileManager: IFileManagerInterface;
}

interface FileManagerPageState {
  isLoading: boolean;
  isUploading: boolean;
}


class FileManagerPage extends React.Component<FileManagerPageProps, FileManagerPageState> {
  constructor(props: FileManagerPageProps) {
    super(props);

    this.state = {
      isLoading: false,
      isUploading: false,
    };
  }

  async componentDidMount() {
    await this.fetchBucketAndListObject();
  }

  setIsLoading(value: boolean) {
    this.setState({ isLoading: value });
  }

  setIsUploading(value: boolean) {
    this.setState({ isUploading: value });
  }

  async fetchBucketAndListObject() {
    const { fileManager, dispatch } = this.props;

    try {
      this.setIsLoading(true);
      await dispatch<any>(FileManagerActions.fetchBuckets());
      if (fileManager.selectedBucket) {
        await this.fetchListBucket(fileManager.selectedBucket);
      }
      this.setIsLoading(false);
    } catch (e) {
      this.setIsLoading(false);
    }
  }

  async fetchListBucket(bucket: string) {
    const { dispatch } = this.props;
    await dispatch<any>(FileManagerActions.fetchListObjects(bucket));
  }

  async selectBucket(bucket?: string) {
    const { dispatch } = this.props;

    if (!bucket) return;

    dispatch(FileManagerActions.FileManagerActions.setListObjects([]));
    dispatch(FileManagerActions.FileManagerActions.setSelectedBucket(bucket));
    try {
      this.setIsLoading(true);
      await dispatch<any>(FileManagerActions.fetchListObjects(bucket));
      this.setIsLoading(false);
    } catch (error) {
      this.setIsLoading(false);
    }
  }

  selectElement(element: IListObject): void {
    const { dispatch } = this.props;
    dispatch(FileManagerActions.FileManagerActions.setSelectedListObject(element));
  }

  async downloadFile() {
    const { fileManager, dispatch } = this.props;

    try {
      const url: PresignedUrlDTO = await dispatch<any>(FileManagerActions.downloadFile(fileManager.selectedBucket, fileManager.selectedListObject!.key!));
      window.open(url.presignedUrl, "download");
      dispatch(FileManagerActions.FileManagerActions.setSelectedListObject(undefined));
    } catch (e) {
      dispatch<any>(showModal('DOWNLOAD_FILE_ERROR', {
        modalType: ModalTypes.ALERT_DIALOG,
        modalProps: {
          title: <ReduxLanguage t="error" />,
          message: <ReduxLanguage t="fileManager.downloadFileError" />,
        },
      }));
    }
  }

  async uploadFile(file: File) {
    const { fileManager, dispatch } = this.props;
    try {
      this.setIsUploading(true);
      await dispatch<any>(FileManagerActions.uploadFile(fileManager.selectedBucket, fileManager?.selectedListObject?.key || "", file));
      dispatch(FileManagerActions.FileManagerActions.setSelectedListObject(undefined));
      await this.fetchListBucket(fileManager.selectedBucket);
      this.setIsUploading(false);
    } catch (e) {
      this.setIsUploading(false);
      dispatch<any>(showModal('DOWNLOAD_FILE_ERROR', {
        modalType: ModalTypes.ALERT_DIALOG,
        modalProps: {
          title: <ReduxLanguage t="error" />,
          message: <ReduxLanguage t="fileManager.uploadFileError" />,
        },
      }));
    }
  }

  refreshBucket() {
    const { dispatch } = this.props;
    dispatch(FileManagerActions.FileManagerActions.setSelectedListObject(undefined));
    this.fetchBucketAndListObject();
  }

  async deleteRequest() {
    const { dispatch } = this.props;
    dispatch<any>(showModal('DOWNLOAD_FILE_ERROR', {
      modalType: ModalTypes.ALERT_DIALOG,
      modalProps: {
        title: <ReduxLanguage t="fileManager.delete" />,
        message: <ReduxLanguage t="fileManager.deleteElementMessage" />,
        onConfirm: () => this.delete()
      },
    }));
  }

  async delete() {
    const { dispatch, fileManager } = this.props;
    try {
      await dispatch<any>(FileManagerActions.deleteFile(fileManager.selectedBucket, fileManager.selectedListObject!.key!));
      dispatch(FileManagerActions.FileManagerActions.setSelectedListObject(undefined));
      this.fetchListBucket(fileManager.selectedBucket);
    } catch (e) {
      dispatch<any>(showModal('DELETE_FILE_ERROR', {
        modalType: ModalTypes.ALERT_DIALOG,
        modalProps: {
          title: <ReduxLanguage t="error" />,
          message: <ReduxLanguage t="fileManager.deleteElementError" />,
        },
      }));
    }
  }

  render() {
    const { fileManager } = this.props;
    const { isUploading, isLoading } = this.state;

    return (
      <ComponentAppBarWrapper>
        <Box sx={{ display: 'flex', height: '100%', flexDirection: 'column', paddingLeft: 3, paddingRight: 3 }}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight="bold">
              <ReduxLanguage t='drawer.fileManager' />
            </Typography>
            {isLoading ? <CircularProgress style={{ marginLeft: 10 }} size={22} thickness={8} /> : null}
          </div>
          <div style={{ display: 'flex', marginTop: 30, marginBottom: 30, }}>
            <FormControl style={{ flex: 1, justifyContent: 'center' }}>
              <InputLabel id="demo-simple-select-label"><ReduxLanguage t="fileManager.selectBucket" /></InputLabel>
              <Select
                fullWidth
                value={fileManager.selectedBucket}
                onChange={e => this.selectBucket(e.target.value)}
                sx={{ backgroundColor: "white" }}
                label={<ReduxLanguage t="fileManager.selectBucket" />}
              >
                {
                  _.map(fileManager.buckets, b => <MenuItem key={b} value={b}>{b}</MenuItem>)
                }
              </Select>
            </FormControl>
            <IconButton
              sx={{ m: 1 }}
              onClick={() => this.refreshBucket()}
            >
              <RefreshIcon color="primary" fontSize={"medium"} />
            </IconButton>
          </div>
          {fileManager.selectedBucket ?
            <FileManagerComponent
              isUploadingFile={isUploading}
              listObjects={_.cloneDeep(fileManager.bucketListObject)}
              onSelectElement={e => this.selectElement(e)}
              selectedListObject={fileManager.selectedListObject}
              onDownload={() => this.downloadFile()}
              onUpload={(file) => this.uploadFile(file)}
              onDelete={() => this.deleteRequest()}
            /> :
            <div style={{ display: 'flex', justifyContent: 'center', flex: 1, alignItems: 'center' }}>
              <Typography variant="h5" fontWeight="bold" color="text.secondary" align="center">
                <ReduxLanguage t="fileManager.selectBucket" />
              </Typography>
            </div>
          }
        </Box>
      </ComponentAppBarWrapper>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    fileManager: state.fileManager
  }
}

export default connect(mapStateToProps)(FileManagerPage);