import { Box } from '@mui/system';
import React from 'react';
import { connect } from 'react-redux';
import IDispatchProps from '../../redux/IDispatchProps';
import * as installationActions from '../../redux/installation/installation.actions';
import { Installation, InstallationStatusEnum } from '../../redux/installation/model/Installations';
import { InstanceDetailsOutDTO } from '../../repository/vitruvian/client';
import { instanceApi } from '../../repository/vitruvian/vitruvianApi';
import { getLogsFromCreatiobLogs } from '../../utils/installations';
import TerminalComponent from '../Console/TerminalComponent';
import InstallationDetailsDescription from './InstallationDetailsDescription';
import InstallationDetailsGraph from './InstallationDetailsGraph';

interface InstallationDetailsProps extends IDispatchProps {
  installation: Installation;
}

interface InstallationDetailsState {
  installationDetails?: InstanceDetailsOutDTO;
}

class InstallationDetails extends React.Component<InstallationDetailsProps, InstallationDetailsState> {

  private installationDetailsInterval: NodeJS.Timeout | null = null;

  constructor(props: InstallationDetailsProps) {
    super(props);

    this.state = {
      installationDetails: undefined,
    }
  }

  async componentDidMount() {
    const { installation } = this.props;

    try {
      if (!installation.id) return;
      this.getInstallationnDetailsPolling();
      const details = await instanceApi.getInstanceDetailsUsingGET(installation.id);
      this.setState({
        installationDetails: details.data
      });
    } catch (e) {
      // TODO: handle error
    }
  }

  scrollToBottom() {
    var terminal = document.getElementById("vitruvian-terminal");
    if (terminal) {
      terminal.scrollTop = terminal.scrollHeight;
    }
  }

  componentWillUnmount() {
    this.clearInstallationPolling();
  }

  clearInstallationPolling() {
    if (this.installationDetailsInterval) {
      clearInterval(this.installationDetailsInterval);
    }
  }

  componentDidUpdate(prevProps: InstallationDetailsProps, prevState: InstallationDetailsState) {
    const { installationDetails } = this.state;

    if (installationDetails?.creationLog !== prevState.installationDetails?.creationLog) {
      this.scrollToBottom();
    }
  }

  getInstallationnDetailsPolling() {
    const { installation, dispatch } = this.props;

    this.clearInstallationPolling();
    this.installationDetailsInterval = setInterval(async () => {
      //if (!installation.status || installation.status !== InstallationStatusEnum.PENDING) return;
      const details = await instanceApi.getInstanceDetailsUsingGET(installation.id!);
      this.setState({
        installationDetails: details.data
      });
      if (details.data.status === InstallationStatusEnum.ACTIVE) {
        dispatch<any>(installationActions.updateInstallationState(details.data));
        this.clearInstallationPolling();
      }
    }, 2000);
  }

  requestTicket(installation: Installation) {
    let windowParams = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=900,height=700,left=-1000,top=-1000`;
    //window.open(`https://toshibasupport.deskpro.com/new-ticket`, "_blank", windowParams);
    window.open("https://sofialocks.freshdesk.com/support/home", "_blank", windowParams);
  }

  render() {
    const { installation } = this.props;
    const { installationDetails } = this.state;

    return (
      <React.Fragment>
        <Box style={{ display: 'flex', flex: 1, flexWrap: 'wrap' }}>
          <div style={{ flex: 1 }}>
            <InstallationDetailsDescription
              onRequestTicket={(i) => this.requestTicket(i)}
              installation={installation}
              installationDetails={installationDetails}
            />
          </div>
          <div style={{ flex: 1, marginTop: 20 }}>
            {installationDetails ?
              <React.Fragment>
                {installationDetails.status === InstallationStatusEnum.PENDING ?
                  <div style={{ width: 600, height: 420 }}>
                    <TerminalComponent
                      rows={getLogsFromCreatiobLogs(this.state.installationDetails?.creationLog || "")}
                    />
                  </div> :
                  <InstallationDetailsGraph
                    installationsDetails={installationDetails}
                  />
                }
              </React.Fragment> :
              null
            }
          </div>
        </Box>
      </React.Fragment>
    );
  }
}


export default connect()(InstallationDetails);
