import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React from 'react';
import { IModalViewProps } from '../../redux/modal/model/ModalTypes';
import ReduxLanguage from '../Language/ReduxLanguage';

interface ModalProps {
  title: string | JSX.Element;
  message: string | JSX.Element;
  successButtonLabel?: string | JSX.Element;
  errorButtonLabel?: string | JSX.Element;
  onConfirm?: () => void;
};

class AlertDialog extends React.Component<IModalViewProps<ModalProps>> {

  private handleClose = (): void => {
    const { hideModal } = this.props;
    hideModal();
  }

  private handleConfirm = () => {
    const { hideModal } = this.props;
    if (!this.props.modalProps) return;
    const { onConfirm } = this.props.modalProps;
    if (onConfirm) {
      onConfirm();
    }
    hideModal();
  }
  render() {
    if (!this.props.modalProps) return null;
    const { title, message, successButtonLabel, errorButtonLabel } = this.props.modalProps;
    return (
      <Dialog
        open
        onClose={() => this.handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.handleClose()}>{errorButtonLabel ? errorButtonLabel : <ReduxLanguage t="cancel" />}</Button>
          <Button onClick={() => this.handleConfirm()}>
            {successButtonLabel ? successButtonLabel : <ReduxLanguage t="ok" />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default AlertDialog;