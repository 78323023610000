import { FileBrowserFile } from "react-keyed-file-browser";
import { IListObject } from "../../redux/fileManager/model/FileManager";
import _ from 'lodash';

export function convertListObjectToFileManagerObject(listObj: IListObject[]): FileBrowserFile[] {
  return _.map(listObj, (obj, index) => {
    const fileBroserObj: FileBrowserFile = {
      key: obj.key || "" + index,
      modified: obj.lastModified || 0,
      size: obj.size || 0,
    }

    return fileBroserObj;
  });
}

export function convertFileBrowserFileToListObject(fileBrowser: FileBrowserFile): IListObject {
  return {
    key: fileBrowser.key,
    lastModified: fileBrowser.modified,
    size: fileBrowser.size,
  } as IListObject;
}

export function isObjectKeyFolder(listObjectKey: string) {
  return _.endsWith(listObjectKey, '/');
}
