import { Card, CardContent, Typography } from '@mui/material';
import React from 'react';
import { OnboardingData } from '../../redux/installation/model/Onboarding';
import { formatLocationName, formatTemplateName } from '../../utils/installations';
import ReduxLanguage from '../Language/ReduxLanguage';

interface InstallationSummaryCardProps {
  onboardingData: OnboardingData;
}

class InstallationSummaryCard extends React.Component<InstallationSummaryCardProps> {
  render() {
    const { onboardingData: { installationData, selectedLocation, selectedTemplate } } = this.props;
    if (!installationData || !selectedLocation || !selectedTemplate) return null;
    return (
      <Card style={{ width: '100%' }}>
        <CardContent>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            <Typography variant="h5" fontWeight="bold" color="text.secondary">
              {installationData.name}
            </Typography>
            <Typography variant="h5" fontWeight="bold" color="text.secondary">
              {formatTemplateName(selectedTemplate.size || "", selectedTemplate.environment || "", selectedTemplate?.providerOutDTO?.name || "")}
            </Typography>
            <div>

            </div>
          </div>
          <Typography color="text.secondary" variant="h6" fontWeight="light">
            {installationData.description}
          </Typography>
          {installationData.version ?
            <div style={{ display: 'flex', marginTop: 20 }}>
              <Typography variant="h6" fontWeight="light" color="text.secondary"> <ReduxLanguage t="installations.visualStoreVersion" />:&nbsp;</Typography>
              <Typography variant="h6" color="text.secondary">{installationData.version.version}</Typography>
            </div> :
            null
          }
          <div style={{ marginTop: 10, width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
              <Typography variant="h6" fontWeight="light" color="text.secondary">
                <ReduxLanguage t="installations.location" />:&nbsp;
              </Typography>
              <Typography variant="h6" color="text.secondary">
                {formatLocationName(selectedLocation, selectedTemplate)}
              </Typography>
            </div>
          </div>
        </CardContent>
      </Card>
    );
  }
}

export default InstallationSummaryCard;
