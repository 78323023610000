import SeamlessImmutable from 'seamless-immutable';
import UsersActionTypesEnum from './model/UsersActionTypesEnum';
import { UsersActionsType } from './users.actions';
import { User } from './model/Users';


export interface IUsersInterface {
  currentUser: User;
}

const initialState = SeamlessImmutable<IUsersInterface>({
  currentUser: {
    username: '',
    isAuthenticated: false,
  }
});

export default function userReducers(state: SeamlessImmutable.ImmutableObject<IUsersInterface> = initialState, action: UsersActionsType) {
  switch (action.type) {
    case UsersActionTypesEnum.SET_CURRENT_LOGGED_USER:
      return state.set("currentUser", action.payload);
    default:
      return state;
  }
}
