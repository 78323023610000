import { Button, Typography } from '@mui/material';
import React from 'react';
import { BUTTON_BORDER_COLOR } from '../../config/style';
import ReduxLanguage from '../Language/ReduxLanguage';

interface SignInCloudButtonProps {
  title: string;
  imgPath: string;
  onClick: () => void;
}
interface SignInCloudButtonState { }

class SignInCloudButton extends React.Component<SignInCloudButtonProps, SignInCloudButtonState> {
  render() {
    const { title, imgPath, onClick } = this.props;
    return (
      <Button
        variant="outlined"
        sx={{ backgroundColor: "white", height: 45, width: 250, borderColor: BUTTON_BORDER_COLOR, padding: 0 }}
        size="small"
        style={{ margin: 10 }}
        onClick={() => onClick()}
      >
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <img src={imgPath} width={30} height={30} style={{ marginLeft: 5 }} alt="sign-in-cloud-button" />
          <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
            <Typography
              sx={{ fontSize: 12 }}
              align="center"
              color="text.secondary"
              variant="h6"
              noWrap
            >
              <ReduxLanguage t={title} />
            </Typography>
          </div>

        </div>
      </Button>
    );
  }
}

export default SignInCloudButton;