import { Button, TextField, Box, Typography } from '@mui/material';
import React from 'react';
import { BUTTON_BORDER_COLOR } from '../../config/style';
import ButtonLink from '../Buttons/ButtonLink';
import ReduxLanguage from '../Language/ReduxLanguage';

export interface LoginData {
  username: string;
  password: string;

}

interface LoginFormProps {
  onConfirm: (loginData: LoginData) => void;
}

interface LoginFormState {
  loginData: LoginData;
}

class LoginForm extends React.Component<LoginFormProps, LoginFormState> {
  constructor(props: LoginFormProps) {
    super(props);
    this.state = {
      loginData: {
        username: "",
        password: "",
      }
    };
  }

  public onLoginValueChange(field: string, value: string) {
    this.setState({
      loginData: {
        ...this.state.loginData,
        [field]: value
      }
    });
  }

  public doLogin() {
    const { loginData } = this.state;
    const { onConfirm } = this.props;

    onConfirm(loginData);
  }

  handleEnterPress(event: any) {
    const { loginData } = this.state;

    if (event.key === 'Enter' && loginData.password.trim() && loginData.username.trim()) {
      this.doLogin()
    }
  }


  render() {
    const { loginData } = this.state;

    const isLoginButtonDisabled = !loginData.username || !loginData.password;
    return (
      <Box
        style={{}}
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          height: '100%',
          width: '100%',
          minWidth: 500,
          justifyContent: 'center'
        }}
      >
        <TextField
          size="small"
          autoComplete='off'
          fullWidth
          label={<ReduxLanguage t="login.username" />}
          id="username"
          onChange={e => this.onLoginValueChange("username", e.target.value)}
          sx={{
            backgroundColor: "white",
          }}
          variant="outlined"
          onKeyPress={(event) => this.handleEnterPress(event)}
        />
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 15 }}>
          <TextField
            size="small"
            autoComplete='off'
            fullWidth
            label={<ReduxLanguage t="login.password" />}
            id="password"
            onChange={e => this.onLoginValueChange("password", e.target.value)}
            sx={{ backgroundColor: "white" }}
            type="password"
            onKeyPress={(event) => this.handleEnterPress(event)}
          />
          <ButtonLink
            label={<ReduxLanguage t="login.forgotPassword" />}
            labelColor="black"
            labelUnderline="hover"
            labelAlignment="right"
            labelFontWeight="bold"
            fontSize={12}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Button
            disabled={isLoginButtonDisabled}
            variant="outlined"
            onClick={() => this.doLogin()}
            sx={{
              backgroundColor: isLoginButtonDisabled ? "whitesmoke" : "white",
              width: "50%",
              height: 45,
              borderColor: BUTTON_BORDER_COLOR
            }}
            style={{ marginBottom: 8, marginTop: 30, color: "black" }}
          >
            <Typography align="right" fontWeight="bold">
              <ReduxLanguage t="login.login" />
            </Typography>
          </Button>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            <Typography fontSize={12}>
              <ReduxLanguage t="login.signUpMessage" />
            </Typography>
            <ButtonLink
              label={<ReduxLanguage t="login.signUp" />}
              labelColor="black"
              labelUnderline="hover"
              labelFontWeight="bold"
              fontSize={12}
              style={{ marginLeft: 5 }}
            />
          </div>
        </div>
      </Box>
    );
  }
}

export default LoginForm;