import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../redux';
import { OnboardingData } from '../../redux/installation/model/Onboarding';
import InstallationSummaryCard from '../Installations/InstallationSummaryCard';

interface InstallationSummaryProps {
  installationData: OnboardingData;
}
class InstallationSummary extends React.Component<InstallationSummaryProps> {

  render() {
    return (
      <div style={{ height: '70%', width: '100%', display: 'flex', alignItems: 'center', flex: 1, justifyContent: 'center', overflow: 'scroll' }}>
        <div style={{ width: '80%', display: 'flex' }}>
          <InstallationSummaryCard
            onboardingData={this.props.installationData}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    installationData: state.installation.onboardingData,
  }
}

export default connect(mapStateToProps)(InstallationSummary);
