import { Popover, Typography, IconButton } from '@mui/material';
import React from 'react';
import { getInstallationStatusColor } from '../../config/style';
import { InstallationStatusEnum } from '../../redux/installation/model/Installations';
import ReduxLanguage from '../Language/ReduxLanguage';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface InstallationStatusProps {
  status: InstallationStatusEnum;
}

const InstallationStatus: React.FunctionComponent<InstallationStatusProps> = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getPopoverMessage = () => {
    const { status } = props;
    switch (status) {
      case InstallationStatusEnum.ACTIVE:
        return <ReduxLanguage t="installations.status.statusActiveMessage" />;
      case InstallationStatusEnum.PENDING:
        return <ReduxLanguage t="installations.status.statusPendingMessage" />;
      case InstallationStatusEnum.ERROR:
        return <ReduxLanguage t="installations.status.statusErrorMessage" />;
      default:
        return <ReduxLanguage t="installations.status.statusErrorMessage" />;
    }
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <IconButton onClick={(e) => handleClick(e)} size="small">
          <HelpOutlineIcon fontSize="inherit" />
        </IconButton>
        <Popover
          id="status-help-icon"
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          PaperProps={{
            style: { minWidth: 200, maxWidth: 400 },
          }}
        >
          <Typography sx={{ p: 2 }} style={{ wordWrap: 'break-word' }}>{getPopoverMessage()}</Typography>
        </Popover>
        <Typography variant="h6">
          <ReduxLanguage t={`installations.status.${props.status}`} />
        </Typography>
      </div>
      <div style={{ marginLeft: 10, width: 15, height: 15, borderRadius: 7.5, backgroundColor: getInstallationStatusColor(props.status) }} />
    </div>
  );
}

export default InstallationStatus;
