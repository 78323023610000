import { RouterLocation, RouterState } from "connected-react-router";
import { AppRouterPath } from "../../routes/AppRouter";

export function isAuthRoute(path?: string): boolean {
  if (path === AppRouterPath.LOGIN) return true;

  return false;
}

export function getRouteLocation(router: RouterState<unknown>): RouterLocation<unknown> {
  return router.location;
}

export function getRouteLocationPath(router: RouterState<unknown>): string {
  return getRouteLocation(router).pathname;
}