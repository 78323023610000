import SeamlessImmutable from 'seamless-immutable';
import TemplateActionTypesEnum from './model/TemplateActionTypesEnum';
import { TemplateActionsType } from './template.actions';
import { Template } from './model/Template';


export interface ITemplateInterface {
  templates: Template[];
  selectedTemplate?: Template;
}

const initialState = SeamlessImmutable<ITemplateInterface>({
  templates: [],
  selectedTemplate: undefined
});

export default function templateReducers(state: SeamlessImmutable.ImmutableObject<ITemplateInterface> = initialState, action: TemplateActionsType) {
  switch (action.type) {
    case TemplateActionTypesEnum.SET_TEMPLATES:
      return state.set('templates', action.payload);
    case TemplateActionTypesEnum.SET_SELECTED_TEMPLATE:
      return state.set('selectedTemplate', action.payload);
    default:
      return state;
  }
}
