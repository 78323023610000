import React from 'react';
import _ from 'lodash';
import Ansi from 'ansi-to-react';

interface TerminalComponentProps {
  rows: string[];
}

const TerminalComponent: React.FunctionComponent<TerminalComponentProps> = (props) => {
  return (
    <div style={{ display: 'flex', backgroundColor: '#252a33', borderRadius: '8px', flexDirection: 'column', height: '100%', width: '100%' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 40 }}>
        <p style={{ color: 'gray', margin: 0, fontWeight: "bolder" }}>Vitruvian Console</p>
      </div>
      <div id="vitruvian-terminal" style={{ display: 'flex', flexDirection: 'column', paddingLeft: 15, paddingBottom: 15, height: 'calc(100% - 24px)', width: 'calc(100% - 24px)', overflow: 'scroll' }}>
        {_.map(props.rows, (row, index) =>
          <p key={index} style={{ color: 'white', margin: 2 }}><Ansi key={index}>{row}</Ansi>
          </p>)}
      </div>
    </div>
  );
}

export default TerminalComponent;
