import { Box, Divider, Typography } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router';
import IDispatchProps from '../../redux/IDispatchProps';
import { MAIN_BACKGROUND_COLOR } from '../../config/style';
import LoginForm, { LoginData } from '../../components/Forms/LoginForm';
import ReduxLanguage from '../../components/Language/ReduxLanguage';
import CloudLogin from '../../components/Login/CloudLogin';
import Vitruvian from '../../assets/Images/Vitruvian2.png';
import * as usersActions from '../../redux/users/users.actions';
import { ModalTypes } from '../../redux/modal/model/ModalTypes';
import { showModal } from '../../redux/modal/modal.actions';
interface LoginPageProps extends IDispatchProps {
}

interface LoginPageState {

}
class LoginPage extends React.Component<LoginPageProps, LoginPageState> {

  public async doLogin(data: LoginData) {
    const { dispatch } = this.props;

    try {
      await dispatch<any>(usersActions.loginUser(data.username, data.password));
      dispatch(replace("/installations"));
    } catch (error) {
      dispatch<any>(showModal('LOGIN_ERROR', {
        modalType: ModalTypes.ALERT_DIALOG,
        modalProps: {
          title: <ReduxLanguage t="error" />,
          message: <ReduxLanguage t="login.loginErrorMessage" />,
        },
      }));
    }
  }

  render() {
    return (
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        backgroundColor: MAIN_BACKGROUND_COLOR,
      }}>
        <img src={Vitruvian} alt="login" />
        {/* <Typography
          sx={{ color: "black" }}
          fontWeight="bold"
          variant="h2"
        >
          VITRUVIAN
        </Typography> */}
        <Typography variant="h4" style={{ color: "rgb(112,112,112)" }} fontWeight="bold">
          <ReduxLanguage t="login.signIn" />
        </Typography>
        <div>
          <CloudLogin />
        </div>
        <Divider
          sx={{
            backgroundColor: "white",
            width: "30%",
            height: 8
          }}
        >
          <div style={{ backgroundColor: MAIN_BACKGROUND_COLOR, width: 100 }}>
            <Typography style={{ color: "rgb(112,112,112)" }} fontSize={20}>
              <ReduxLanguage t="or" />
            </Typography>
          </div>
        </Divider>
        <div>
          <LoginForm
            onConfirm={data => this.doLogin(data)}
          />
        </div>
      </Box>
    );
  }
}

export default connect()(LoginPage);