import React from 'react';
import { DEFAULT_BASE_API_URL } from '../../config';
import { authApi } from '../../repository/authorization/authorizationApi';
import { createApiInstances } from '../../repository/vitruvian/vitruvianApi';

interface ConfigurationComponentProps { }

interface ConfigurationComponentState {
  canRender: boolean;
}

export default class ConfigurationComponent extends React.Component<ConfigurationComponentProps, ConfigurationComponentState> {

  constructor(props: ConfigurationComponentProps) {
    super(props);

    this.state = {
      canRender: false,
    }
  }

  async componentDidMount() {
    try {
      await this.fetchBackendInfo();
      this.setState({ canRender: true });
    } catch (e) {
      this.setState({ canRender: false });
    }
  }
  async fetchBackendInfo() {
    try {
      let baseApiUrl = DEFAULT_BASE_API_URL;
      if (process.env.NODE_ENV === 'production') {
        const backendData = await authApi.getBackendInfo();
        baseApiUrl = backendData.data.baseUrl;
      }
      createApiInstances(baseApiUrl);
    } catch (error) {
      // TODO: handle error
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.state.canRender ? this.props.children : null}
      </React.Fragment>
    );
  }
}