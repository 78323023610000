import IStorageService from "../IStorageService";
import StorageKeys from "../model/StorageKeys";
import _ from "lodash";

class StorageService implements IStorageService {
  public async setItem(key: string, value: any): Promise<void> {
    try {
      const jsonValue = JSON.stringify(value);
      await localStorage.setItem(key, jsonValue);
    } catch (error) { } // TODO: how to handle error
  }

  public async getItem<T = any>(key: string): Promise<T | null> {
    try {
      const jsonValue: string | null = await localStorage.getItem(key);
      if (jsonValue) {
        return JSON.parse(jsonValue);
      }
    } catch (error) { } // TODO: how to handle error

    return null;
  }

  public async getOrDefaultItem<T = any>(key: string, defaultValue: T): Promise<T> {
    try {
      const jsonValue: string | null = await localStorage.getItem(key);
      if (jsonValue) {
        return JSON.parse(jsonValue);
      }
    } catch (error) {
    } // TODO: how to handle error

    return defaultValue;
  }

  public async removeItem(key: string): Promise<void> {
    try {
      await localStorage.removeItem(key);
    } catch (error) { } // TODO: how to handle error
  }

  public async removeItems(keys: string[]): Promise<void> {
    try {
      await localStorage.multiRemove(keys);
    } catch (error) { } // TODO: how to handle error
  }

  public async getAllKeys(): Promise<string[]> {
    try {
      return await localStorage.getAllKeys();
    } catch (error) { } // TODO: how to handle error
    return [];
  }

  public async clearAll(): Promise<void> {
    try {
      await this.clearWithoutKeys([]);
    } catch (error) { } // TODO: how to handle error
  }

  public async clearWithoutKeys(keys: StorageKeys[]): Promise<void> {
    try {
      const localStorageKeys = await this.getAllKeys();
      const keysToDelete = _.filter(localStorageKeys, k => !_.includes(keys, k));
      localStorageKeys.length && await this.removeItems(keysToDelete);
    } catch (error) {

    }
  }
}

export default new StorageService() as IStorageService;
