import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { IState } from '../../redux';
import IDispatchProps from '../../redux/IDispatchProps';
import * as modalActions from '../../redux/modal/modal.actions';
import ModalProps from '../../redux/modal/model/ModalProps';
import { ModalTypes } from '../../redux/modal/model/ModalTypes';
import AlertDialog from './AlertDialog';

interface BaseModalProps extends IDispatchProps {
  modals: ModalProps<Object>[];
}

const modalComponents: any = {
  [ModalTypes.ALERT_DIALOG]: AlertDialog,
};

class BaseModal extends React.Component<BaseModalProps>  {
  public render() {
    const { modals, dispatch } = this.props;
    if (!_.size(modals)) {
      return null;
    }

    const components = _.map(modals, (modal) => {
      if (!modal.id || !modal.data) return null;

      const { modalType, modalProps } = modal.data;
      const Component = modalComponents[modalType];
      return <Component key={modal.id} modalProps={modalProps} dispatch={dispatch} hideModal={() => dispatch<any>(modalActions.hideModal(modal.id))} />;
    });
    return (_.size(components) ? components : null);
  }
};

function mapDispatchToProps(state: IState) {
  return {
    modals: state.modals.data
  }
}

export default connect(mapDispatchToProps)(BaseModal);
