import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import _ from 'lodash';
import InstallationCard from './InstallationCard';
import { Installation, Installations } from '../../redux/installation/model/Installations';
import { InstanceOutDTO } from '../../repository/vitruvian/client';
import { CircularProgress } from '@mui/material';
import moment from 'moment';

interface InstallationsListProps {
  installations: Installations;
  onLoadMore: (nextPage: number) => void;
  selectedInstallation?: Installation;
  onSelectInstallation: (installation?: Installation) => void;
}

class InstallationsList extends React.Component<InstallationsListProps> {
  render() {
    const { installations, onLoadMore, selectedInstallation, onSelectInstallation } = this.props;

    return (
      <div style={{ height: '100%', overflow: 'scroll' }}>
        <InfiniteScroll
          initialLoad={false}
          loadMore={() => onLoadMore((installations.number || 0) + 1)}
          hasMore={!installations.last}
          loader={<CircularProgress key={moment().valueOf()} />}
          useWindow={false}
        >
          {
            _.map(installations.content, (el: InstanceOutDTO) => (
              <div key={el.id} style={{ padding: 10 }}>
                <InstallationCard
                  installation={el}
                  selectedInstallation={selectedInstallation}
                  onSelectInstallation={i => onSelectInstallation(i)}
                />
              </div>
            ))
          }
        </InfiniteScroll>
      </div>
    );
  }
}

export default InstallationsList;