import { Box, Drawer, List, ListItemText, Link, ListItemButton, Typography } from '@mui/material';
import { push, RouterState } from 'connected-react-router';
import React from 'react';
import { connect } from 'react-redux';
import { DRAWER_BACKGROUND_COLOR } from '../../config/style';
import IDispatchProps from '../../redux/IDispatchProps';
import * as usersActions from '../../redux/users/users.actions';
import ReduxLanguage from '../Language/ReduxLanguage';
import { withStyles } from '@mui/styles';
import LanguageSelector from '../Language/LanguageSelector';
import { AppRouterPath } from '../../routes/AppRouter';
import { IState } from '../../redux';
import { getRouteLocationPath } from '../../utils/router';
import _ from 'lodash';


interface DrawerElement {
  key: string;
  label: string | JSX.Element;
  onClick: () => void;
  disabled: boolean;
}

interface VitruvianDrawerProps extends IDispatchProps {
  classes: any;
  router: RouterState<unknown>;
}

interface VitruvianDrawerState {
  selectedElementIndex: number;
}

export const styles = {
  selected: {
    backgroundColor: "white !important"
  }
};

const style = withStyles(styles);


class VitruvianDrawer extends React.Component<VitruvianDrawerProps, VitruvianDrawerState> {
  constructor(props: VitruvianDrawerProps) {
    super(props);

    this.state = {
      selectedElementIndex: 0,
    };
  }

  private drawerListElement: DrawerElement[] = [
    {
      key: 'home',
      label: <ReduxLanguage t='drawer.home' />,
      onClick: () => { },
      disabled: true,
    },
    {
      key: AppRouterPath.FILE_MANAGER,
      label: <ReduxLanguage t='drawer.fileManager' />,
      onClick: () => this.goTo(AppRouterPath.FILE_MANAGER),
      disabled: false,
    },
    {
      key: AppRouterPath.INSTALLATIONS,
      label: <ReduxLanguage t='drawer.installation' />,
      onClick: () => this.goTo(AppRouterPath.INSTALLATIONS),
      disabled: false,
    },
    {
      key: 'billing',
      label: <ReduxLanguage t='drawer.billing' />,
      onClick: () => { },
      disabled: true,
    },
    {
      key: 'support',
      label: <ReduxLanguage t='drawer.support' />,
      onClick: () => { },
      disabled: true,
    }
  ];

  componentDidMount() {
    const { router } = this.props;

    const location = getRouteLocationPath(router);
    const index = _.findIndex<any>(this.drawerListElement, (e: DrawerElement) => e.key === location);
    this.setState({
      selectedElementIndex: index !== -1 ? index : 2
    });
  }

  public goTo(route: AppRouterPath) {
    const { dispatch } = this.props;

    dispatch(push(route));
  }

  public doLogout() {
    const { dispatch } = this.props;

    dispatch<any>(usersActions.logoutUser());
    dispatch(push(AppRouterPath.LOGIN));
  }

  public handleListItemClick(element: DrawerElement, index: number) {
    element.onClick();
    this.setState({
      selectedElementIndex: index
    });
  }
  render() {
    const { selectedElementIndex } = this.state;

    return (
      <Drawer
        sx={{
          width: 250,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: 250,
            border: "none",
            backgroundColor: DRAWER_BACKGROUND_COLOR
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flex: 2,
              alignItems: 'flex-end',
              justifyContent: 'center',
              width: '100%'
            }}
            classes={this.props.classes.root}
          >
            <List
              dense
              component="nav"
              aria-label="main mailbox folders"
            >
              {this.drawerListElement.map((element, index) => (
                <ListItemButton
                  key={element.key}
                  disabled={element.disabled}
                  selected={selectedElementIndex === index}
                  onClick={(event) => this.handleListItemClick(element, index)}
                  sx={{
                    borderBottom: 5,
                    borderBottomColor: selectedElementIndex === index ? 'red' : 'white',
                  }}
                  classes={{ selected: this.props.classes.selected }}
                  style={{ marginTop: 10 }}
                >
                  <ListItemText
                    primary={
                      <Typography
                        variant="h6"
                        fontWeight={selectedElementIndex === index ? "bold" : undefined}
                      >
                        {element.label}
                      </Typography>}
                  />
                </ListItemButton>
              ))}
            </List>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              justifyContent: 'flex-end'
            }}
            style={{ paddingBottom: 45 }}
          >
            <LanguageSelector />
            <Link
              style={{ marginTop: 20 }}
              component="button"
              color="red"
              underline="none"
              onClick={() => {
                this.doLogout()
              }}
            >
              <Typography variant="h6" color="text.secondary">
                <ReduxLanguage t="login.logout" />
              </Typography>
            </Link>
          </Box>
        </Box>
      </Drawer>
    );
  }
}

function mapStateToProps(state: IState) {
  return {
    router: state.router
  }
}
export default connect(mapStateToProps)(style(VitruvianDrawer));
