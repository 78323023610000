import { Card, CardActions, CardContent, Collapse, IconButton, IconButtonProps, Typography } from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import InstallationStatus from './InstallationStatus';
import { Installation, InstallationStatusEnum } from '../../redux/installation/model/Installations';
import moment from 'moment';
import InstallationDetails from './InstallationDetails';
import { formatLocationName, formatTemplateName } from '../../utils/installations';
import ReduxLanguage from '../Language/ReduxLanguage';
import InstallationMenu from './InstallationMenu';
import IDispatchProps from '../../redux/IDispatchProps';
import { showModal } from '../../redux/modal/modal.actions';
import { ModalTypes } from '../../redux/modal/model/ModalTypes';
import { connect } from 'react-redux';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

interface InstallationCardProps extends IDispatchProps {
  hasDetails?: boolean;
  installation: Installation;
  selectedInstallation?: Installation;
  onSelectInstallation: (installation?: Installation) => void;
}

interface InstallationCardState {
  expanded: boolean;
}


class InstallationCard extends React.Component<InstallationCardProps, InstallationCardState> {

  constructor(props: InstallationCardProps) {
    super(props);
    let isExpanded = false;
    if (props.selectedInstallation) {
      isExpanded = props.selectedInstallation.id === props.installation.id;
    }
    this.state = {
      expanded: isExpanded,
    }
  }

  handleExpandClick() {
    const { onSelectInstallation, installation } = this.props;
    onSelectInstallation(this.state.expanded ? undefined : installation);
    this.setState({
      expanded: !this.state.expanded,
    });
  };

  entepriseProMessage() {
    const { dispatch } = this.props;

    dispatch<any>(showModal('ENTERPRISE_PRO_VERSION', {
      modalType: ModalTypes.ALERT_DIALOG,
      modalProps: {
        title: <ReduxLanguage t="info" />,
        message: <ReduxLanguage t="entepriseProMessage" />,
      },
    }));
  }
  render() {
    const {
      installation: {
        name,
        templateOutDTO,
        createdAt,
        status,
        userOutDTO,
        locationOutDTO,
        description
      },
    } = this.props;
    const { expanded } = this.state;

    return (
      <Card style={{ width: '100%' }}>
        <CardContent>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography variant="h5" color="text.secondary" fontWeight="bold">
              {name}
            </Typography>
            <Typography variant="h5" fontWeight="bold" color="text.secondary" noWrap>
              {formatTemplateName(templateOutDTO?.size || "", templateOutDTO?.environment || "", templateOutDTO?.providerOutDTO?.name || "")}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {status ?
                <InstallationStatus status={status as InstallationStatusEnum} /> :
                null
              }
              <div style={{ marginLeft: 5 }}>
                <InstallationMenu
                  onActivate={() => this.entepriseProMessage()}
                  onDeactivate={() => this.entepriseProMessage()}
                  onDelete={() => this.entepriseProMessage()}
                  onClone={() => this.entepriseProMessage()}
                  onExport={() => this.entepriseProMessage()}
                />
              </div>
            </div>
          </div>
          <div style={{ marginTop: 10, width: '96%', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap' }}>
            {userOutDTO?.username ?
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="h6" fontWeight="light">
                  <ReduxLanguage t="installations.author" />:&nbsp;
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  {userOutDTO.username}
                </Typography>
              </div> :
              null
            }
            {createdAt ?
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Typography variant="h6" fontWeight="light">
                  <ReduxLanguage t="installations.created" />:&nbsp;
                </Typography>
                <Typography variant="h6" color="text.secondary">
                  {moment(createdAt).format("L")}
                </Typography>
              </div> :
              null
            }
            {locationOutDTO ?
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography variant="h6" fontWeight="light">
                  <ReduxLanguage t="installations.location" />:&nbsp;
                </Typography>
                <Typography variant="h6" color="text.secondary" noWrap>
                  {formatLocationName(locationOutDTO, templateOutDTO)}
                </Typography>
              </div> :
              null
            }
          </div>
        </CardContent>
        <CardActions>
          <Typography color="text.secondary" variant="h6" style={{ marginLeft: 5 }}>
            {description}
          </Typography>
          {this.props.hasDetails !== false ?
            <ExpandMore
              expand={expanded}
              onClick={() => this.handleExpandClick()}
              aria-expanded={expanded}
              aria-label="show more"
            >
              <ExpandMoreIcon />
            </ExpandMore> :
            null
          }
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <InstallationDetails
              installation={this.props.installation}
            />
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default connect()(InstallationCard);
